<template>
	<div class="site-wrap">
		<div class="inner-site-wrap">
			<div 
				class="logo"
			>
				<img 
					@click="goToHome"
					src="@/assets/adis-logo-white.png" alt=""
				>
				<img 
					@click="goToHome"
					class="colour-logo"
					:class="{ 'is-active': isCaseStudy }"
					src="@/assets/adis-logo.png" alt="">
			</div>
			<div 
				class="main-content"
				:class="{
					'full-width': isCaseStudy,
					'should-flex': !isCaseStudy,
				}"
				ref="mainContent"
			>
				<div class="back-button-wrap">
					<button 
						@click="$router.go(-1)"
						class="back-button main-back-button"
						:class="{ 'is-active': !isStartScreen && !isCaseStudy && !selectedCaseStudy }"
					>
						<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 14"><rect width="2" height="9" rx="1" transform="rotate(44.999 2.718 8.259) skewX(-.001)" fill="#8760FF"/><rect width="2" height="9" rx="1" transform="rotate(135.001 1.382 7.654) skewX(.002)" fill="#8760FF"/></svg>
						Go back
					</button>
				</div>

				<breadcrumb
					v-if="!isStartScreen"
					:current-step="currentStep"
				/>

				<div class="small-print-layout-el"></div>

				<div
					class="content-container"
					:class="{
						'restricted' : !isStartScreen
					}"
				>
					<router-view />

					<div 
						class="question-block-wrap"
						:class="{ 'is-active': !isCaseStudy && !selectedCaseStudy }"
					>
						<question-block
							v-show="!isCaseStudy"
							:is-start-screen="isStartScreen"
							:current-step="currentStep"
							:case-studies="caseStudiesToDisplay"
							@step-one-question-clicked="stepOneQuestionClicked"
							@step-two-question-clicked="stepTwoQuestionClicked"
							@case-study-selected="caseStudySelected"
						/>
					</div>
				</div>

				<div
					class="small-print"
					:class="{
						'is-active' : isStartScreen
					}"
				>
					<p>Real World Evidence for Regulatory Use Decision Aid: An Interactive Tool To Inform Clinical Development and Regulatory Strategies” Leah Burns, Robert Kalesnik-Orszulak, Rick Spring, Fabienne Zeegers, Mark Rutstein, Mathias Hukkelhoven, Lisa Wruck, John O'Donnell </p>
					<p>This interactive infographic represents the opinions of the authors. For a full list of declarations, including funding and author disclosure statements, please see the full text online. © The authors, CC-BY-NC 2021.</p>
				</div>
			</div>
			<div 
				v-if="currentStep != 4 || true"
				class="main-illustration"
				:class="{ 'is-case-study': selectedCaseStudy || currentStep == 4 }"
			>
				<div 
					class="main-illustration-inner"
					:class="{ 'is-active': !isCaseStudy && !selectedCaseStudy }"
				>
					<img src="@/assets/base-illustration.svg" alt="" class="is-active">

					<img 
						src="@/assets/illustration-cards-1.svg" alt=""
						:class="{
							'is-active': currentStep === 0
						}"
					>
					<img 
						src="@/assets/illustration-cards-2.svg" alt=""
						:class="{
							'is-active': currentStep === 1
						}"
					>
					<img 
						src="@/assets/illustration-cards-3.svg" alt=""
						:class="{
							'is-active': currentStep === 2
						}"
					>
					<img 
						src="@/assets/illustration-cards-4.svg" alt=""
						:class="{
							'is-active': currentStep === 3 || currentStep === 4
						}"
					>

					<img src="@/assets/base-illustration-comment.svg" alt="" class="is-active high">
					<img src="@/assets/base-illustration-fella.svg" alt="" class="is-active high">
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import QuestionBlock from '@/components/QuestionBlock';
import Breadcrumb from '@/components/Breadcrumb';
import questionTwoOutcomes from '@/data/question-two-outcomes';
import caseStudies from '@/data/case-studies';

export default {
	components: {
		QuestionBlock,
		Breadcrumb
	},

	data() {
		return {
			stepTwo: {
				selection: "",
				questionHeading: "",
				questionIntro: "",
				questionSubHeading: ""
			},
			stepTwoSelection: 0,
			caseStudiesSelection: "",
			selectedCaseStudy: false
		}
	},

	mounted() {
		if ( 
			(this.currentStep === 2 || this.currentStep === 3) && 
			this.stepTwo.selection === "" &&
			this.caseStudiesSelection === ""
		) {
			this.$router.push('/');
		}

		if ( this.currentStep === 0 ) {
			this.setStepTwoCopy();
		}
	},

	watch:{
		$route() {
			this.$refs.mainContent.scrollTo({
				top: 0,
				behavior: 'smooth'
			})
		}
	},

	computed: {
		isStartScreen() {
			return this.$route.name === 'Start';
		},

		isCaseStudy() {
			return this.currentStep === 4;
		},

		currentStep() {
			let step = 0;
			let routeName = this.$route.name;

			if ( routeName === 'RegulatoryObjective' ) {
				step = 1;
			}

			if ( routeName === 'StudyDesignType' ) {
				step = 2;
			}

			if ( routeName === 'CaseStudies' ) {
				step = 3;
			}

			if ( routeName === 'CaseStudy' ) {
				step = 4;
			}

			return step;
		},

		caseStudiesToDisplay() {
			return caseStudies
				.filter(caseStudy => caseStudy.regObjective === this.stepTwo.selection)
				.filter(caseStudy => caseStudy.studyType === this.caseStudiesSelection);
		}
	},

	methods: {
		stepOneQuestionClicked(index) {
			this.stepTwoSelection = index - 1;
			this.setStepTwoCopy();
		},

		stepTwoQuestionClicked(optionSelected) {
			this.caseStudiesSelection = optionSelected;
		},

		setStepTwoCopy() {
			this.stepTwo = questionTwoOutcomes[this.stepTwoSelection];
		},

		goToHome() {
			if ( this.$route.name != "Start" ) {
				this.$router.push('/');
			}
		},

		caseStudySelected() {
			this.selectedCaseStudy = true;

			setTimeout(() => {
				this.selectedCaseStudy = false;
			}, 1000);
		}
	}
};

</script>

<style lang="scss">
	@import "@/scss/fonts.scss";
	@import "@/scss/generic/generic.scss";

	html {
		font-family: 'Poppins', sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.site-wrap {
		overflow-x: hidden;
		width: 100%;
		min-width: 1200px;
	}

	.inner-site-wrap {
		width: 100%;
		max-width: 1440px;
		min-width: 1200px;
		min-height: 100vh;
		display: flex;
		color: #393939;
		font-size: 16px;
		line-height: 1.3;
		position: relative;

		@media ( min-width: 1440px ) {
			margin-left: auto;
			margin-right: auto;
		} 
	}

	.back-button-wrap {
		position: absolute;
		top: 48px;
		right: 48px;
	}

	.back-button {
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 600;
		color: #8760FF;
		border: 1px solid currentColor;
		width: 130px;
		height: 50px;
		border-radius: 10px;
		transition: background-color .3s ease;

		&:hover {
			background-color: rgba(#8760FF, .2);
		}

		svg {
			margin-right: .5em;
			width: 8px;
		}
	}

	.question-block-wrap,
	.main-back-button,
	.main-illustration-inner,
	.content-inner-case-studies,
	.colour-logo {
		opacity: 0;
		transition: opacity .3s ease;

		&.is-active {
			opacity: 1;
		}
	}

	.question-block-wrap {
		margin-top: -32px;
	}

	.logo {
		position: absolute;
		width: 100%;
		z-index: 999;

		img {
			position: absolute;
			right: 44px;
			top: 44px;
			z-index: 1;
			cursor: pointer;
		}
	}

	.main-content {
		width: 944px;
		padding: 0 48px 32px 48px;
		position: relative;
		height: 100vh;
		overflow: auto;
		flex-shrink: 0;

		&.should-flex {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		&.full-width {
			width: 100%;
			overflow: visible;
		}
	}

	.main-illustration {
		flex-grow: 1;
		background-color: #0A1153;
		position: relative;

		&:before {
			content: '';
			background-color: #fff;
			position: absolute;
			top: 0;
			bottom: 0;
			width: 0vw;
			z-index: 101;
			transition: width .7s ease;
		}

		&.is-case-study {
			&:before {
				width: 50vw;
			}	
		}

		&:after {
			content: '';
			position: absolute;
			width: 200vw;
			height: 100%;
			background-color: #0A1153;
		    top: 0;
		    left: 100%;
		}
	}

	.main-illustration-inner {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		height: 100vh;
		overflow: hidden;

		@media (max-width: 1440px) {
			align-items: center;
		}

		img {
			grid-row: 1 / -1;
			grid-column: 1 / -1;
			display: block;
			margin: 0 auto;
			width: 90%;
			margin-top: 50px;
			opacity: 0;
			transition: all .8s ease;
			transform: translateY(-25px);

			&.is-active {
				opacity: 1;
				transform: translateY(0);
			}

			&.high {
				z-index: 100;
			}
		}
	}

	.content-inner {
		padding: 48px;
	}

	.question-heading,
	.question-sub-heading {
		color: #0A1153;
		font-size: 24px;
		font-weight: 700;
	}

	.question-heading {
		margin-bottom: 24px;

		span {
			color: #9370FF;
			font-weight: 700;
		}
	}

	.question-sub-heading {
		margin: 32px 0;
		font-size: 20px;
	}

	.question-intro {
		transition: height .5s ease;
		overflow: hidden;

		p {
			margin-bottom: 24px;
			display: inline;
			line-height: 1.4;
		}

		&.is-displayed p {
			display: block;
		}

		strong {
			color: #8760FF;
		}

		ul {
			padding-left: 20px;
			color: #8760FF;

			span {
				display: block;
				padding-left: 28px;
				color: #393939;
			}

			> li {
				margin-bottom: 4px;
			}

			ul {
				margin: 12px 0;
				list-style: none;
				padding-left: 30px;

				li {
					color: #393939;
					line-height: 1.4;
					display: flex;

					&:before {
						margin-right: .5em;
						content: '-';
						color: #8760FF;
					}
				}
			}

			& + p {
				margin-top: 30px;
			}
		}
	}

	.read-more-toggle-button {
		span {
			color: #8760FF;
			font-weight: 600;
		}

		.is-displayed & {
			padding: 0;
			margin-top: 30px;
			display: block;
		}
	}

	.small-print {
		width: calc( 100% - 96px );
		font-size: 12px;
		text-align: right;
		transition: .3s opacity ease, .3s padding-top ease, .3s height ease;

		&:not(.is-active ) {
			opacity: 0;	
			pointer-events: none;
			padding-top: 0;
			height: 0;
			overflow: hidden;
			margin-top: 0;
		}

		> * + * {
			margin-top: 1em;
		}
	}

	.content-container {
		&.restricted {
			max-height: calc( 100vh - 200px - 48px );
		}
	}
</style>
