<template>
	<table>
		<thead>
			<tr>
				<th>Drug name</th>
				<th>{{ drugName }}</th>
			</tr>
		</thead>
		<tbody>
			<tr 
				v-for="(value, key) in data"
				:key="key"
			>
				<td>{{ key }}</td>
				<td>{{ value }}</td>
			</tr>

			<tr>
				<td colspan="2" class="case-overview">
					<p>Case Overview</p>
					<p>{{ caseOverview }}</p>
				</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
export default {
	name: 'drugs-table',

	props: {
		drugName: {
			type: String,
			default: () => ""
		},

		data: {
			type: Object,
			default: () => {}
		},

		caseOverview: {
			type: String,
			default: () => ""
		}
	}
};

</script>

<style lang="scss" scoped>
	table {
		border-radius: 20px;
		overflow: hidden;
		font-size: 14px;
		box-shadow: 4px 20px 32px rgba(135, 96, 255, 0.3);
		background-color: #fff;

		th {
			text-align: left;
			background-color: #EDE7FF;
		}

		th, td {
			width: 50%;
			padding: 20px 24px;
			border-bottom: 1px solid #D5C7FF;

			&:first-child {
				color: #0A1153;
				font-weight: 700;
			}
		}

		.case-overview {
			p {
				color: #393939;
				margin-bottom: 24px;

				&:first-child {
					color: #0A1153;
					font-weight: 700;
				}		
			}
		}
	}
</style>