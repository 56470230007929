<template>
	<div class="view">
		<div class="content-inner">
			<h1>RWE for Regulatory Use Decision Aid</h1>

			<p class="intro">The interactive Decision Aid aims to help identify potential areas of opportunity for RWE to support regulatory strategies and clinical development plans, by matching regulatory objectives of interest and corresponding RW study design types with real-life case examples. This piece is open access and has been peer reviewed.</p>

			<h2>What the tool will cover:</h2>

			<ol class="cover-blocks">
				<li>
					<i>1</i>
					<span>Regulatory Objective</span>
				</li>
				<li>
					<i>2</i>
					<span>RWE Study Design Type</span>
				</li>
				<li>
					<i>3</i>
					<span>Applicable Precedents</span>
				</li>
			</ol>
		</div>
	</div>
</template>

<script>



export default {
	name: 'start'
};

</script>

<style lang="scss" scoped>
	h1 {
		color: #0A1153;
		font-size: 32px;
		font-weight: 700;
		margin-bottom: 24px;
	}

	.intro {
		max-width: 625px;
	}

	h2 {
		color: #0A1153;
		font-size: 20px;
		font-weight: 700;
		margin-top: 48px;
		margin-bottom: 32px;
		text-align: center;
	}

	.cover-blocks {
		list-style: none;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 16px;
		margin-top: -16px;
		margin-bottom: 16px;

		li {
			display: flex;
			align-items: center;
		}

		i {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 10px;
			color: #8760FF;
			font-style: normal;
			width: 40px;
			height: 40px;
			background: #FDFCFF;
			border: 1px solid #D5C7FF;
			box-shadow: 0px 16px 24px rgba(135, 96, 255, 0.15);
			border-radius: 100%;
			font-size: 18px;
			font-weight: 700;
		}

		span {
			display: block;
			font-size: 12px;
			font-weight: 700;
			color: #0A1153;
		}
	}
</style>





 

