var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"site-wrap"},[_c('div',{staticClass:"inner-site-wrap"},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("@/assets/adis-logo-white.png"),"alt":""},on:{"click":_vm.goToHome}}),_c('img',{staticClass:"colour-logo",class:{ 'is-active': _vm.isCaseStudy },attrs:{"src":require("@/assets/adis-logo.png"),"alt":""},on:{"click":_vm.goToHome}})]),_c('div',{ref:"mainContent",staticClass:"main-content",class:{
				'full-width': _vm.isCaseStudy,
				'should-flex': !_vm.isCaseStudy,
			}},[_c('div',{staticClass:"back-button-wrap"},[_c('button',{staticClass:"back-button main-back-button",class:{ 'is-active': !_vm.isStartScreen && !_vm.isCaseStudy && !_vm.selectedCaseStudy },on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('svg',{attrs:{"fill":"none","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 8 14"}},[_c('rect',{attrs:{"width":"2","height":"9","rx":"1","transform":"rotate(44.999 2.718 8.259) skewX(-.001)","fill":"#8760FF"}}),_c('rect',{attrs:{"width":"2","height":"9","rx":"1","transform":"rotate(135.001 1.382 7.654) skewX(.002)","fill":"#8760FF"}})]),_vm._v(" Go back ")])]),(!_vm.isStartScreen)?_c('breadcrumb',{attrs:{"current-step":_vm.currentStep}}):_vm._e(),_c('div',{staticClass:"small-print-layout-el"}),_c('div',{staticClass:"content-container",class:{
					'restricted' : !_vm.isStartScreen
				}},[_c('router-view'),_c('div',{staticClass:"question-block-wrap",class:{ 'is-active': !_vm.isCaseStudy && !_vm.selectedCaseStudy }},[_c('question-block',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCaseStudy),expression:"!isCaseStudy"}],attrs:{"is-start-screen":_vm.isStartScreen,"current-step":_vm.currentStep,"case-studies":_vm.caseStudiesToDisplay},on:{"step-one-question-clicked":_vm.stepOneQuestionClicked,"step-two-question-clicked":_vm.stepTwoQuestionClicked,"case-study-selected":_vm.caseStudySelected}})],1)],1),_c('div',{staticClass:"small-print",class:{
					'is-active' : _vm.isStartScreen
				}},[_c('p',[_vm._v("Real World Evidence for Regulatory Use Decision Aid: An Interactive Tool To Inform Clinical Development and Regulatory Strategies” Leah Burns, Robert Kalesnik-Orszulak, Rick Spring, Fabienne Zeegers, Mark Rutstein, Mathias Hukkelhoven, Lisa Wruck, John O'Donnell ")]),_c('p',[_vm._v("This interactive infographic represents the opinions of the authors. For a full list of declarations, including funding and author disclosure statements, please see the full text online. © The authors, CC-BY-NC 2021.")])])],1),(_vm.currentStep != 4 || true)?_c('div',{staticClass:"main-illustration",class:{ 'is-case-study': _vm.selectedCaseStudy || _vm.currentStep == 4 }},[_c('div',{staticClass:"main-illustration-inner",class:{ 'is-active': !_vm.isCaseStudy && !_vm.selectedCaseStudy }},[_c('img',{staticClass:"is-active",attrs:{"src":require("@/assets/base-illustration.svg"),"alt":""}}),_c('img',{class:{
						'is-active': _vm.currentStep === 0
					},attrs:{"src":require("@/assets/illustration-cards-1.svg"),"alt":""}}),_c('img',{class:{
						'is-active': _vm.currentStep === 1
					},attrs:{"src":require("@/assets/illustration-cards-2.svg"),"alt":""}}),_c('img',{class:{
						'is-active': _vm.currentStep === 2
					},attrs:{"src":require("@/assets/illustration-cards-3.svg"),"alt":""}}),_c('img',{class:{
						'is-active': _vm.currentStep === 3 || _vm.currentStep === 4
					},attrs:{"src":require("@/assets/illustration-cards-4.svg"),"alt":""}}),_c('img',{staticClass:"is-active high",attrs:{"src":require("@/assets/base-illustration-comment.svg"),"alt":""}}),_c('img',{staticClass:"is-active high",attrs:{"src":require("@/assets/base-illustration-fella.svg"),"alt":""}})])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }