import Vue from "vue";
import VueRouter from "vue-router";
import Start from "../views/Start";
import RegulatoryObjective from "../views/RegulatoryObjective";
import StudyDesignType from "../views/StudyDesignType";
import CaseStudies from "../views/CaseStudies";
import CaseStudy from "../views/CaseStudy";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Start",
		component: Start,
	},
	{
		path: "/regulatory-objective",
		name: "RegulatoryObjective",
	    component: RegulatoryObjective
	},
	{
		path: "/study-design-type",
		name: "StudyDesignType",
	    component: StudyDesignType
	},
	{
		path: "/case-studies",
		name: "CaseStudies",
	    component: CaseStudies
	},
	{
		path: "/case-studies/:casestudy",
		name: "CaseStudy",
	    component: CaseStudy
	},
];

const router = new VueRouter({
	routes,
});

export default router;
