<template>
	<div 
		class="question-block"
		:class="[
			`step-${currentStep}`,
			animationClass
		]"
	>
		<div class="question-blobs">
			<svg class="blob-1" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 375 389"><path fill-rule="evenodd" clip-rule="evenodd" d="M156.835 351.074c-45.495-14.88-70.772-59.104-89.289-103.261-18.45-43.999-32.376-93.021-10.887-135.63 22.142-43.905 68.919-70.09 117.695-76.154 45.849-5.7 92.263 12.02 123.372 46.183 27.644 30.357 23.437 73.712 23.205 114.783-.253 44.901 8.169 94.928-24.562 125.661-36.415 34.192-92.074 43.941-139.534 28.418z" fill="#E1D7FF"/></svg>
			<svg class="blob-2" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 406 419"><path fill-rule="evenodd" clip-rule="evenodd" d="M144.677 45.91c50.736-11.316 98.851 15.978 140.616 46.955 41.616 30.867 81.79 68.933 85.334 120.635 3.653 53.276-25.521 103.654-67.715 136.349-39.66 30.733-92.828 39.907-140.815 25.242-42.641-13.032-62.74-55.854-85.284-94.34-24.647-42.075-60.238-84.13-46.702-130.97C45.17 97.665 91.75 57.715 144.677 45.911z" fill="#E1D7FF"/></svg>
		</div>

		<div 
			v-if="currentStep === 0"
			class="start"
		>
			<p>Do you have a situation where you may be considering RWE? If yes, click below to enter the tool</p>

			<button @click="startClicked()">
				Start now
			</button>
		</div>

		<div 
			class="question-cards"
			:class="{
				'visible': currentStep === 1
			}"
		>
			<question-card 
				:index="1"
				@click.native="stepOneQuestionClicked(1)"
				heading="New product approval"
			/>

			<question-card
				:index="2"
				@click.native="stepOneQuestionClicked(2)"
				heading="Label expansion"
				sub-heading="(new indication or additional clinical data)"
				illustration-svg="<svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'><path d='M37.56 26.092v3.169s1.267.697 1.267 1.584v1.585c0 1.33.19 2.852-1.14 2.852 0 2.915-3.42 5.577-6.334 5.577H31.1c-2.913 0-6.46-2.598-6.46-5.577-1.33 0-1.013-1.521-1.013-2.852v-1.585c0-.887 0-1.584 1.266-1.584v-2.662c0-2.092 1.077-3.867 3.167-3.867 3.99-1.394 9.5-4.563 9.5 3.36zM36.927 41.493c4.37 0 7.98 3.17 7.98 8.24h-26.98c0-5.07 3.61-8.113 7.98-8.113M17.927 25.267A3.907 3.907 0 0014 29.197M46.427 25.267a3.907 3.907 0 013.926 3.93' stroke='#8760FF' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/><path d='M37.56 26.092v3.169s1.267.697 1.267 1.584v1.585c0 1.33 0 2.788-1.14 2.852 0 2.915-3.42 5.577-6.334 5.577H31.1c-2.913 0-6.46-2.598-6.46-5.577-1.077-.127-1.013-1.521-1.013-2.852v-1.585c0-.887 0-1.584 1.266-1.584v-2.662c0-2.092 1.077-3.867 3.167-3.867 3.99-1.394 9.5-4.563 9.5 3.36zM36.927 41.493c4.37 0 7.98 3.17 7.98 8.24h-26.98c0-5.07 3.61-8.113 7.98-8.113M17.927 25.267A3.907 3.907 0 0014 29.197M46.427 25.267a3.907 3.907 0 013.926 3.93' stroke='#8760FF' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/><path d='M24.26 18.169c0 1.775-1.393 6.338-3.167 6.338-1.773 0-3.166-4.563-3.166-6.338A3.137 3.137 0 0121.093 15a3.137 3.137 0 013.167 3.169zM40.093 18.169c0 1.775 1.394 6.338 3.167 6.338s3.167-4.563 3.167-6.338A3.137 3.137 0 0043.26 15a3.137 3.137 0 00-3.167 3.169z' stroke='#8760FF' stroke-miterlimit='10'/><circle cx='15' cy='36' r='4.5' fill='#E1D7FF' stroke='#8760FF'/><circle cx='49' cy='39' r='4.5' fill='#E1D7FF' stroke='#8760FF'/><path stroke='#8760FF' d='M49.1 37v4M51 39.1h-4M15.1 34v4M17 36.1h-4'/></svg>"
				illustration-svg-hover-alt="<svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'><path d='M37.56 26.091v3.17s1.267.697 1.267 1.584v1.584c0 1.331.19 2.853-1.14 2.853 0 2.915-3.42 5.577-6.334 5.577H31.1c-2.913 0-6.46-2.599-6.46-5.577-1.33 0-1.013-1.521-1.013-2.852v-1.585c0-.887 0-1.584 1.266-1.584v-2.662c0-2.092 1.077-3.867 3.167-3.867 3.99-1.394 9.5-4.563 9.5 3.36zM36.927 41.493c4.37 0 7.98 3.17 7.98 8.24h-26.98c0-5.07 3.61-8.113 7.98-8.113M17.927 25.268A3.907 3.907 0 0014 29.198M46.427 25.268a3.907 3.907 0 013.926 3.93' stroke='#fff' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/><path d='M37.56 26.091v3.17s1.267.697 1.267 1.584v1.584c0 1.331 0 2.79-1.14 2.853 0 2.915-3.42 5.577-6.334 5.577H31.1c-2.913 0-6.46-2.599-6.46-5.577-1.077-.127-1.013-1.521-1.013-2.852v-1.585c0-.887 0-1.584 1.266-1.584v-2.662c0-2.092 1.077-3.867 3.167-3.867 3.99-1.394 9.5-4.563 9.5 3.36zM36.927 41.493c4.37 0 7.98 3.17 7.98 8.24h-26.98c0-5.07 3.61-8.113 7.98-8.113M17.927 25.268A3.907 3.907 0 0014 29.198M46.427 25.268a3.907 3.907 0 013.926 3.93' stroke='#fff' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/><path d='M24.26 18.169c0 1.775-1.393 6.338-3.167 6.338-1.773 0-3.166-4.563-3.166-6.338A3.137 3.137 0 0121.093 15a3.137 3.137 0 013.167 3.169zM40.093 18.169c0 1.775 1.394 6.338 3.167 6.338s3.167-4.563 3.167-6.338A3.137 3.137 0 0043.26 15a3.137 3.137 0 00-3.167 3.169z' stroke='#fff' stroke-miterlimit='10'/><circle cx='15' cy='36' r='4.5' fill='#8760FF' stroke='#fff'/><circle cx='49' cy='39' r='4.5' fill='#8760FF' stroke='#fff'/><path stroke='#fff' d='M49.1 37v4M51 39.1h-4M15.1 34v4M17 36.1h-4'/></svg>"
			/>

			<question-card
				:index="3"
				@click.native="stepOneQuestionClicked(3)"
				heading="Postmarketing commitment or requirement"
				illustration-svg="<svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'><path d='M14 46.683h37M18.317 14v37' stroke='#8760FF' stroke-miterlimit='10' stroke-linejoin='round'/><path d='M21.955 44.71L26 36.5l8 1.5.5-11.5 9.038 2.793 4.625-12.333' stroke='#8760FF' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/><circle cx='33.5' cy='38.5' r='2' fill='#E1D7FF' stroke='#8760FF'/><circle cx='34.5' cy='26.5' r='2' fill='#E1D7FF' stroke='#8760FF'/><circle cx='48' cy='17' r='3.5' fill='#E1D7FF' stroke='#8760FF'/></svg>"
				illustration-svg-hover-alt="<svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'><path d='M14 46.683h37M18.317 14v37' stroke='#fff' stroke-miterlimit='10' stroke-linejoin='round'/><path d='M21.955 44.71L26 36.5l8 1.5.5-11.5 9.038 2.793 4.625-12.333' stroke='#fff' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/><circle cx='33.5' cy='38.5' r='2' fill='#8760FF' stroke='#fff'/><circle cx='34.5' cy='26.5' r='2' fill='#8760FF' stroke='#fff'/><circle cx='48' cy='17' r='3.5' fill='#8760FF' stroke='#fff'/></svg>"
			/>
		</div>

		<div 
			class="question-cards"
			:class="{
				'grid-columns-2': !showPragmaticTrial,
				'grid-columns-1': !showExternalControl && !showPragmaticTrial,
				'visible': currentStep === 2
			}"
		>
			<question-card
				v-if="showExternalControl"
				@click.native="stepTwoQuestionClicked('External Control')"
				heading="External Control"
				illustration-svg="<svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'><path d='M37.121 19.637c4.849 4.848 4.849 12.705 0 17.553-4.849 4.85-12.705 4.85-17.492 0-4.849-4.787-4.849-12.705 0-17.553 4.848-4.85 12.705-4.85 17.492 0z' fill='#E1D7FF' stroke='#8760FF' stroke-miterlimit='10'/><path stroke='#8760FF' d='M34.1 47v4M36 49.1h-4M21.1 10v4M23 12.1h-4'/><path d='M48.476 46.09a1.668 1.668 0 010 2.394l-.123.123a1.668 1.668 0 01-2.394 0L35.894 38.48l2.516-2.517L48.476 46.09z' stroke='#8760FF' stroke-miterlimit='10'/><path d='M43.515 15.562v2.18s.872.48.872 1.092v1.09c0 .917 0 1.92-.785 1.964 0 2.007-2.354 3.84-4.36 3.84h-.174c-2.006 0-4.447-1.79-4.447-3.84-.741-.088-.698-1.047-.698-1.964v-1.09c0-.611 0-1.091.872-1.091V15.91c0-1.44.741-2.662 2.18-2.662 2.747-.96 6.54-3.141 6.54 2.313zM43.08 26.163c3.007 0 5.492 2.181 5.492 5.672H30c0-3.49 2.485-5.585 5.493-5.585M23.745 35.305v1.411s.564.31.564.706v.706c0 .593 0 1.242-.507 1.27 0 1.299-1.524 2.485-2.821 2.485h-.113c-1.298 0-2.878-1.158-2.878-2.485-.48-.056-.451-.677-.451-1.27v-.706c0-.395 0-.706.564-.706V35.53c0-.931.48-1.722 1.41-1.722 1.778-.62 4.232-2.032 4.232 1.497zM23.463 42.165c1.947 0 3.555 1.411 3.555 3.67H15c0-2.259 1.608-3.614 3.555-3.614' stroke='#8760FF' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/></svg>"
				illustration-svg-hover-alt="<svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'><path d='M37.121 19.637c4.849 4.848 4.849 12.705 0 17.553-4.849 4.85-12.705 4.85-17.492 0-4.849-4.787-4.849-12.705 0-17.553 4.848-4.85 12.705-4.85 17.492 0z' stroke='#FDFCFF' stroke-miterlimit='10'/><path stroke='#FDFCFF' d='M34.1 47v4M36 49.1h-4M21.1 10v4M23 12.1h-4'/><path d='M48.476 46.09a1.668 1.668 0 010 2.394l-.123.123a1.668 1.668 0 01-2.394 0L35.894 38.48l2.516-2.517L48.476 46.09z' stroke='#FDFCFF' stroke-miterlimit='10'/><path d='M43.515 15.562v2.18s.872.48.872 1.092v1.09c0 .917 0 1.92-.785 1.964 0 2.007-2.354 3.84-4.36 3.84h-.174c-2.006 0-4.447-1.79-4.447-3.84-.741-.088-.698-1.047-.698-1.964v-1.09c0-.611 0-1.091.872-1.091V15.91c0-1.44.741-2.662 2.18-2.662 2.747-.96 6.54-3.141 6.54 2.313zM43.08 26.163c3.007 0 5.492 2.181 5.492 5.672H30c0-3.49 2.485-5.585 5.493-5.585M23.745 35.305v1.411s.564.31.564.706v.706c0 .593 0 1.242-.507 1.27 0 1.299-1.524 2.485-2.821 2.485h-.113c-1.298 0-2.878-1.158-2.878-2.485-.48-.056-.451-.677-.451-1.27v-.706c0-.395 0-.706.564-.706V35.53c0-.931.48-1.722 1.41-1.722 1.778-.62 4.232-2.032 4.232 1.497zM23.463 42.165c1.947 0 3.555 1.411 3.555 3.67H15c0-2.259 1.608-3.614 3.555-3.614' stroke='#FDFCFF' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/></svg>"
			/>

			<question-card
				@click.native="stepTwoQuestionClicked('Observational Study')"
				heading="Observational Study"
				illustration-svg="<svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'><path d='M33.692 36.16c2.091-1.045 2.657-3.702 2.657-6.054 1.09 0 1.786-.48 1.786-1.09V26.84c0-.61 0-1.09-1.742-1.09v-2.177c0-4.226-2.788-4.356-5.01-3.92M42.404 36.16c2.09-1.045 2.657-3.746 2.657-6.054 1.089 0 1.786-.48 1.786-1.09V26.84c0-.61 0-1.09-1.742-1.09v-2.177c0-4.226-2.788-4.356-5.01-3.92' stroke='#8760FF' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/><path d='M23.108 36.64c3.005 0 5.488-3.529 5.488-6.534 1.089 0 1.699-.48 1.699-1.09V26.84c0-.61-.871-1.09-.871-1.09v-2.177c2.613-3.267-2.832-6.534-7.188-3.267-4.355 0-5.009 1.48-5.009 3.267v2.178s-.871.479-.871 1.089v2.178c0 .61.392 1.089 1.481 1.089 0 3.005 2.265 6.533 5.27 6.533z' fill='#E1D7FF' stroke='#8760FF' stroke-miterlimit='10'/><path d='M20.625 35.986l-1.873 2.744h-1.656c-3.005 0-5.096 1.438-5.096 4.443v1.22h22.65v-1.22c0-3.005-2.482-4.443-5.488-4.443h-1.655l-1.699-2.744c.044 0-2.657 1.612-5.183 0z' fill='#E1D7FF' stroke='#8760FF' stroke-miterlimit='10'/><path d='M43.362 44.393v-1.22c0-3.005-2.482-4.443-5.488-4.443H36.22l-1.699-2.744M52.074 44.393v-1.22c0-3.005-2.483-4.443-5.488-4.443H44.93l-1.699-2.744' stroke='#8760FF' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/></svg>"
				illustration-svg-hover-alt="<svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'><path d='M33.692 36.16c2.091-1.045 2.657-3.702 2.657-6.054 1.09 0 1.786-.48 1.786-1.09V26.84c0-.61 0-1.09-1.742-1.09v-2.177c0-4.225-2.788-4.356-5.01-3.92M42.404 36.16c2.09-1.045 2.657-3.746 2.657-6.054 1.089 0 1.786-.48 1.786-1.09V26.84c0-.61 0-1.09-1.742-1.09v-2.177c0-4.225-2.788-4.356-5.01-3.92' stroke='#FDFCFF' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/><path d='M23.108 36.64c3.005 0 5.488-3.529 5.488-6.534 1.089 0 1.699-.48 1.699-1.09V26.84c0-.61-.871-1.09-.871-1.09v-2.177c2.613-3.267-2.832-6.534-7.188-3.267-4.355 0-5.009 1.48-5.009 3.267v2.178s-.871.479-.871 1.089v2.178c0 .61.392 1.089 1.481 1.089 0 3.005 2.265 6.533 5.27 6.533z' stroke='#FDFCFF' stroke-miterlimit='10'/><path d='M20.625 35.986l-1.873 2.744h-1.656c-3.005 0-5.096 1.438-5.096 4.443v1.22h22.65v-1.22c0-3.005-2.482-4.443-5.488-4.443h-1.655l-1.699-2.744c.044 0-2.657 1.612-5.183 0z' stroke='#FDFCFF' stroke-miterlimit='10'/><path d='M43.362 44.393v-1.22c0-3.005-2.482-4.443-5.488-4.443H36.22l-1.699-2.744M52.074 44.393v-1.22c0-3.005-2.483-4.443-5.488-4.443H44.93l-1.699-2.744' stroke='#FDFCFF' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/></svg>"
			/>

			<question-card
				v-if="showPragmaticTrial"
				@click.native="stepTwoQuestionClicked('Pragmatic Trial')"
				heading="Pragmatic Trial"
				illustration-svg="<svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'><path d='M55.54 26.752c0-3.12-2.51-5.714-5.657-5.714-2.285 0-4.233 1.353-5.132 3.308C43.89 22.39 41.904 21 39.657 21 36.51 21 34 23.632 34 26.752c0 1.428.524 2.33 1.423 3.835L44.79 41l9.327-10.413c.9-1.504 1.424-2.407 1.424-3.835z' fill='#E1D7FF' stroke='#8760FF' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/><path d='M35.648 33.594h5.882l1.798-6.767 1.91 10.451 1.873-3.684h7.268' stroke='#8760FF' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/><path d='M34.75 28.119c0 4.32-3.482 9.4-7.573 9.4-3.482 0-7.241-5.605-7.241-9.4v-.642c0-4.321 3.482-9.401 7.573-9.401 3.482 0 7.241 5.605 7.241 9.4v.643z' fill='#FDFCFF' stroke='#8760FF' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/><path d='M30.952 40.634c7.534-9.928.765-19.619-.177-21.462-3.002 3.33-16.186-3.329-8.888 21.462l-5.062 1.13C19.768 39.98 11.057 14 26.831 14c17.835 0 8.653 21.521 12.597 28.834l-1.295.237c-1.943.357-4.003-.059-5.65-1.248l-1.531-1.189z' fill='#E1D7FF' stroke='#8760FF' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/><path d='M30.318 36.594v3.877h3.275c3.766 0 6.222 4.708 6.222 8.529L14 48.779c0-3.822 2.183-8.308 5.949-8.308h3.82v-3.877s2.729 2.215 6.55 0z' fill='#FDFCFF' stroke='#8760FF' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/></svg>"
				illustration-svg-hover-alt="<svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'><path d='M55.54 26.752c0-3.12-2.51-5.714-5.657-5.714-2.285 0-4.233 1.353-5.132 3.308C43.89 22.39 41.904 21 39.657 21 36.51 21 34 23.632 34 26.752c0 1.428.524 2.33 1.423 3.835L44.79 41l9.327-10.413c.9-1.504 1.424-2.407 1.424-3.835z' stroke='#FDFCFF' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/><path d='M35.648 33.594h5.881l1.798-6.767 1.91 10.451 1.874-3.684h7.267M34.75 28.119c0 4.32-3.482 9.4-7.573 9.4-3.482 0-7.241-5.605-7.241-9.4v-.642c0-4.321 3.482-9.401 7.573-9.401 3.482 0 7.241 5.605 7.241 9.4v.643z' stroke='#FDFCFF' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/><path d='M30.952 40.634c7.534-9.928.765-19.619-.177-21.462-3.002 3.33-16.186-3.329-8.888 21.462l-5.062 1.13C19.768 39.98 11.057 14 26.831 14c17.835 0 8.653 21.521 12.597 28.834l-1.295.237c-1.943.357-4.003-.059-5.65-1.248l-1.531-1.189z' fill='#8760FF' stroke='#FDFCFF' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/><path d='M30.318 36.594v3.877h3.275c3.766 0 6.222 4.708 6.222 8.529L14 48.779c0-3.822 2.183-8.308 5.949-8.308h3.82v-3.877s2.729 2.215 6.55 0z' fill='#8760FF' stroke='#FDFCFF' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/></svg>"
			/>
		</div>

		<div 
			class="case-study-cards"
			:class="{ 
				'less-than-four-items': caseStudies.length < 4,
				'visible': currentStep === 3,
				'inactive': caseStudiesInactive
			}"
		>
			<case-study-card
				v-for="(caseStudy, index) in caseStudies"
				:key="caseStudy.name"
				:index="index"
				:case-study-name="caseStudy.name"
				:case-study-drug-name="caseStudy.drugName"
				@click.native="caseStudySelected(caseStudy.url)"
			/>
		</div>
	</div>
</template>

<script>

import QuestionCard from '@/components/QuestionCard';
import CaseStudyCard from '@/components/CaseStudyCard';

export default {
	name: 'question-block',

	components: {
		QuestionCard,
		CaseStudyCard
	},

	data() {
		return {
			animationClass: '',
			caseStudiesInactive: true
		}
	},

	watch: {
		currentStep(newStep, oldStep) {
			if ( newStep === 3 ) {
				this.caseStudiesInactive = false;
			}

			if ( oldStep === 3 ) {
				setTimeout(() => {
					this.caseStudiesInactive = true;
				}, 400);
			}
		}
	},

	props: {
		currentStep: {
			type: Number,
			default: () => 0
		},

		caseStudies: {
			type: Array,
			default: () => []
		}
	},

	computed: {
		showExternalControl() {
			const selection = this.$parent.$data.stepTwo.selection;
			return selection === "New Product Approval" || selection === "Label Expansion";
		},

		showPragmaticTrial() {
			const selection = this.$parent.$data.stepTwo.selection;
			return selection === "Label Expansion";
		}
	},

	methods: {
		startClicked() {
			this.animationClass = "animate-start-out";

			setTimeout(() => {
				this.$router.push('/regulatory-objective');
				this.animationClass = "";
			}, 300);
		},

		stepOneQuestionClicked(index) {
			this.$router.push('/study-design-type');
			this.$emit('step-one-question-clicked', index);
		},

		stepTwoQuestionClicked(optionSelected) {
			this.$emit('step-two-question-clicked', optionSelected);
			this.$router.push('/case-studies');
		},

		caseStudySelected(caseStudySelectedUrl) {
			this.$emit('case-study-selected');
			
			setTimeout(() => {
				this.$router.push(`/case-studies/${caseStudySelectedUrl}`);
			}, 750);
		}	
	}
};

</script>

<style lang="scss" scoped>
	.question-block {
		// background: #EDE7FF;
		// border-radius: 16px;
		// min-height: 256px;
		position: relative;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		align-items: flex-start;

		> * {
			grid-row: 1 / -1;
			grid-column: 1 / -1;
		}
	}

	.question-blobs {
		display: none;
		border-radius: 16px;
		overflow: hidden;
		position: relative;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
	}

	.blob-1,
	.blob-2 {
		position: absolute;
	}

	.blob-1 {
		top: 0;
		left: 0;
		width: 300px;
		transform: translate(-20%, -30%);
		transition: transform .8s ease;

		.step-1 & {
			transform: translate(-15%, 15%) rotate(90deg);
		}

		.step-2 & {
			transform: translate(23%, -20%) rotate(87deg) scale(1.2);
		}

		.step-3 & {
			transform: translate(26%, 27%) rotate(62deg) scale(1.2);
		}
	}

	.blob-2 {
		bottom: 0;
		right: 0;
		width: 370px;
		transform: translate(20%, 47.5%);
		transition: transform .8s ease;

		.step-1 & {
			transform: translate(20%, 5%);
		}

		.step-2 & {
			transform: translate(1%, 47.5%) scale(1.2);
		}

		.step-3 & {
			transform: translate(-20%, -20%) scale(1.2) rotate(20deg);
		}
	}

	.start {
		text-align: center;
		position: relative;

		p {
			font-size: 20px;
			font-weight: 700;
			color: #0A1153;
			padding: 0 110px 32px;
			transition: opacity .3s ease;

			.animate-start-out & {
				opacity: 0;
			}
		}

		button {
			background: #8760FF;
			box-shadow: 4px 8px 32px rgba(135, 96, 255, 0.45);
			border-radius: 8px;
			color: #fff;
			font-weight: 600;
			font-size: 14px;
			width: 122px;
			height: 48px;
			transition: all .3s ease;

			.animate-start-out & {
				opacity: 0;
			}

			&:hover {
				background-color: #4A358C;
			}
		}
	}

	.question-cards {
		padding: 0 48px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 16px;
		position: relative;
		margin-left: auto;
		margin-right: auto;
		pointer-events: none;

		&.grid-columns-2 {
			width: 592px;
			grid-template-columns: repeat(2, 1fr);
		}

		&.grid-columns-1 {
			width: 336px;
			grid-template-columns: repeat(1, 1fr);
		}

		> * {
			opacity: 0;
			transform: translateY(25px);
			transition: all .5s ease;

			&:nth-child(2) {
				transition-delay: .05s;
			}

			&:nth-child(3) {
				transition-delay: .1s;
			}

			&:nth-child(4) {
				transition-delay: .15s;
			}

			&:nth-child(5) {
				transition-delay: .2s;
			}

			&:nth-child(6) {
				transition-delay: .25s;
			}
		}

		&.visible {
			pointer-events: auto;

			> * {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}

	.case-study-cards {
		padding: 0 48px;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 16px;
		pointer-events: none;

		> * {
			opacity: 0;
			transform: translateY(25px);
			transition: all .5s ease;

			&:nth-child(2) {
				transition-delay: .05s;
			}

			&:nth-child(3) {
				transition-delay: .1s;
			}

			&:nth-child(4) {
				transition-delay: .15s;
			}

			&:nth-child(5) {
				transition-delay: .2s;
			}

			&:nth-child(6) {
				transition-delay: .25s;
			}
		}

		&.visible {
			pointer-events: auto;

			> * {
				opacity: 1;
				transform: translateY(0);
			}
		}

		&.inactive {
			> * {
				height: 0;
			}
		}
	}
</style>





 

