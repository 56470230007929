<template>
	<button class="question-card">
		<div class="content">
			<span class="option">
				option {{ index }}
			</span>
			<h3>
				{{ heading }}
			</h3>
			<h4>
				{{ subHeading }}
			</h4>
		</div>
		<div class="footer">
			<i 
				class="illustration"
				v-html="illustrationSvg"
			/>
			<i 
				class="illustration hovered"
				v-html="illustrationSvgHoverAlt"
			/>
			<i class="arrow">
				<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 15"><rect x="8.071" y="14.142" width="1" height="10" rx=".5" transform="rotate(-135 8.071 14.142)" /><rect width="1" height="10" rx=".5" transform="rotate(-45 4.535 -8.536)" /><rect y="7.5" width="1" height="14" rx=".5" transform="rotate(-90 0 7.5)" /></svg>
			</i>
		</div>
	</button>
</template>

<script>
export default {
	name: 'question-card',

	props: {
		index: {
			type: [Number, String],
			default: () => ""
		},

		heading: {
			type: String,
			default: () => ""
		},

		subHeading: {
			type: String,
			default: () => ""
		},

		illustrationSvg: {
			type: String,
			default: () => '<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><path d="M31.204 48H17V23.724L24.5 16H42v14.897" stroke="#8760FF" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M17.661 23.509h7.509V16" stroke="#8760FF" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><circle cx="39.141" cy="41.414" r="6.431" fill="#E1D7FF" stroke="#8760FF"/><path d="M36 41.766L38.024 44l4.476-4" stroke="#8760FF" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path stroke="#8760FF" d="M19.661 29.5h16M19.661 32.5h11M19.661 35.5h8M15.1 14v4M17 16.1h-4M36.1 5v4M38 7.1h-4"/><circle cx="36.5" cy="21.5" r="2" stroke="#8760FF"/></svg>'
		},

		illustrationSvgHoverAlt: {
			type: String,
			default: () => '<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><path d="M31.204 48H17V23.724L24.5 16H42v14.897" stroke="#fff" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M17.661 23.509h7.509V16" stroke="#fff" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><circle cx="39.141" cy="41.414" r="6.431" stroke="#fff"/><path d="M36 41.766L38.024 44l4.476-4" stroke="#fff" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path stroke="#fff" d="M19.661 29.5h16M19.661 32.5h11M19.661 35.5h8M15.1 14v4M17 16.1h-4M36.1 5v4M38 7.1h-4"/><circle cx="36.5" cy="21.5" r="2" stroke="#FDFCFF"/></svg>'
		}
	}
};

</script>

<style lang="scss" scoped>
	.question-card {
		box-shadow: 0 0 40px rgba(135, 96, 255, 0.2);
		background-color: #fff;
		border-radius: 16px;
		text-align: left;
		padding: 16px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		transition: background-color .8s ease;

		&:hover,
		&:active {
			background-color: #9370FF;

			.arrow {
				background-color: #fff;

				svg {
					fill: #9370FF;
				} 
			}

			.option, h3, h4 {
				color: #fff;
			}

			.illustration {
				display: none;

				&.hovered {
					box-shadow: 0px 16px 24px rgba(43, 17, 123, 0.35);
					background-color: #9370FF;
					display: flex;
				}
			}
		}

		&:active {
			background-color: #4A358C;

			.illustration {
				display: flex;
				background-color: #4A358C;
				position: relative;

				&:before {
					content: '';
					background-color: #fff;
					height: 64px;
					width: 64px;
					position: absolute;
					top: 7px;
					left: 7px;
					border-radius: 100%;
					z-index: 1;
				}

				::v-deep svg {
					position: relative;
					z-index: 5;
				}

				&.hovered {
					background-color: #9370FF;
					display: none;
				}
			}
		}
	}

	.option, h3, h4 {
		transition: color .8s ease;
	}

	.option {
		font-size: 10px;
		line-height: 120%;
		letter-spacing: 2px;
		color: #8760FF;
		font-weight: 700;
		text-transform: uppercase;
	}

	h3 {
		margin-top: 4px;
		font-size: 16px;
		line-height: 1.2;
		color: #0A1153;
		font-weight: 700;
	}

	h4 {
		margin-top: 8px;
		font-size: 12px;
		line-height: 1.2;
		color: #0A1153;
		font-weight: 700;
	}

	.footer {
		margin-top: 8px;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		width: 100%;
	}

	.illustration {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 64px;
		height: 64px;
		box-shadow: 0px 16px 24px rgba(135, 96, 255, 0.15);
		background-color: #fff;
		border-radius: 100%;
		border: 1px solid #EDE7FF;

		&.hovered {
			display: none;
		}

		::v-deep svg {
			width: 64px;
		}
	}

	.arrow {
		width: 36px;
		height: 36px;
		background-color: #8760FF;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 100%;
		transition: background-color .8s ease;

		svg {
			transition: fill .8s ease;
			width: 60%;
			fill: #fff;
		}
	}
</style>