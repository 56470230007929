var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"breadcrumb"},[_c('button',{class:{ 
			'is-active': _vm.currentStep === 1,
			'is-past': _vm.currentStep > 1
		},on:{"click":function($event){return _vm.stepOneClicked()}}},[_c('span',[(_vm.currentStep === 1)?_c('span',{staticClass:"number"},[_vm._v(" 1 . ")]):_c('span',{domProps:{"innerHTML":_vm._s(_vm.tickIcon)}})]),_c('span',{staticClass:"copy"},[_vm._v("REGULATORY OBJECTIVE")])]),_c('button',{class:{ 
			'is-active': _vm.currentStep === 2,
			'is-past': _vm.currentStep > 2
		},on:{"click":function($event){return _vm.stepTwoClicked()}}},[_c('span',[(_vm.currentStep < 3)?_c('span',{staticClass:"number"},[_vm._v(" 2 . ")]):_c('span',{domProps:{"innerHTML":_vm._s(_vm.tickIcon)}})]),_vm._m(0)]),_c('button',{class:{ 
			'is-active': _vm.currentStep === 3,
			'is-past': _vm.currentStep > 3
		},on:{"click":function($event){return _vm.stepThreeClicked()}}},[_c('span',[(_vm.currentStep < 4)?_c('span',{staticClass:"number"},[_vm._v(" 3 . ")]):_c('span',{domProps:{"innerHTML":_vm._s(_vm.tickIcon)}})]),_c('span',{staticClass:"copy"},[_vm._v("APPLICABLE PRECEDENTS")])]),_c('button',{class:{ 'is-active': _vm.currentStep === 4 }},[_vm._m(1),_vm._m(2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"copy"},[_vm._v("RWE STUDY"),_c('br'),_vm._v(" DESIGN TYPE")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('span',{staticClass:"number"},[_vm._v("4 .")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"copy"},[_vm._v("INDIVIDUAL"),_c('br'),_vm._v(" CASE STUDY")])}]

export { render, staticRenderFns }