<template>
	<div class="breadcrumb">
		<button
			:class="{ 
				'is-active': currentStep === 1,
				'is-past': currentStep > 1
			}"
			@click="stepOneClicked()"
		>
			<span>
				<span
					v-if="currentStep === 1"
					class="number"
				>
					1 .
				</span>
				<span 
					v-else
					v-html="tickIcon" 
				/>
			</span>
			<span class="copy">REGULATORY OBJECTIVE</span>
		</button>
		<button
			:class="{ 
				'is-active': currentStep === 2,
				'is-past': currentStep > 2
			}"
			@click="stepTwoClicked()"
		>
			<span>
				<span
					v-if="currentStep < 3"
					class="number"
				>
					2 .
				</span>
				<span 
					v-else
					v-html="tickIcon" 
				/>
			</span>
			<span class="copy">RWE STUDY<br> DESIGN TYPE</span>
		</button>
		<button
			:class="{ 
				'is-active': currentStep === 3,
				'is-past': currentStep > 3
			}"
			@click="stepThreeClicked()"
		>
			<span>
				<span
					v-if="currentStep < 4"
					class="number"
				>
					3 .
				</span>
				<span 
					v-else
					v-html="tickIcon" 
				/>
			</span>
			<span class="copy">APPLICABLE PRECEDENTS</span>
		</button>
		<button
			:class="{ 'is-active': currentStep === 4 }"
		>
			<span>
				<span class="number">4 .</span>
			</span>
			<span class="copy">INDIVIDUAL<br> CASE STUDY</span>
		</button>
	</div>
</template>

<script>
export default {
	name: 'breadcrumb',

	data() {
		return {
			tickIcon: '<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 9"><path d="M1 4.5l3 3L10.5 1" stroke="#33D581" stroke-width="2"/></svg>'
		}
	},

	props: {
		currentStep: {
			type: Number,
			default: () => 0
		}
	},

	methods: {
		stepOneClicked() {
			if ( this.currentStep < 2 ) {
				return false;
			}

			else {
				this.$router.push('/regulatory-objective');
			}
		},

		stepTwoClicked() {
			if ( this.currentStep < 3 ) {
				return false;
			}

			if ( this.$parent.$data.caseStudiesSelection === "" ) {
				this.$router.push('/');
			} else {
				this.$router.push('/study-design-type');
			}
		},

		stepThreeClicked() {
			if ( this.currentStep < 4 ) {
				return false;
			}

			if ( this.$parent.$data.caseStudiesSelection === "" ) {
				this.$router.push('/');
			} else {
				this.$router.push('/case-studies');
			}
		}
	}
};

</script>

<style lang="scss" scoped>
	.breadcrumb {
		margin: 0 0 0 48px;
		width: 624px;
		height: 52px;
		background: #FFFFFF;
		box-shadow: 0px 12px 40px rgba(135, 96, 255, 0.2);
		border-radius: 8px;
		display: flex;
		overflow: hidden;
		position: absolute;
		top: 48px;
		left: 48px;
		z-index: 1;

		button {
			font-size: 9px;
			text-align: left;
			width: 25%;
			padding: 0 24px;
			position: relative;
			transition: background-color .3s ease;

			&:not(:last-child):after {
				content: '';
				height: 48px;
				width: 1px;
				background-color: #EDE7FF;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
			}

			&.is-active {
				background-color: #E1D7FF;

				.number {
					color: #8760FF;
					opacity: 1;
				}

				.copy {
					opacity: 1;
				}
			}

			&.is-past {
				.copy {
					opacity: 1;
				}
			}
		}

		::v-deep svg {
			width: 1em;
		}

		.copy,
		.number {
			display: block;
			font-weight: 700;
			color: #0A1153;
			opacity: .4;
		}
	}
</style>