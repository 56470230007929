<template>
	<div 
		class="case-study"
		:class="{ 'is-active': mounted }"
	>
		<svg class="blob-1" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 375 389"><path fill-rule="evenodd" clip-rule="evenodd" d="M156.835 351.074c-45.495-14.88-70.772-59.104-89.289-103.261-18.45-43.999-32.376-93.021-10.887-135.63 22.142-43.905 68.919-70.09 117.695-76.154 45.849-5.7 92.263 12.02 123.372 46.183 27.644 30.357 23.437 73.712 23.205 114.783-.253 44.901 8.169 94.928-24.562 125.661-36.415 34.192-92.074 43.941-139.534 28.418z" fill="#E1D7FF"/></svg>

		<div class="header">
			<h1>{{ caseStudy.description }}</h1>

			<div class="buttons">
				<a
					v-if="caseStudy.pdf"
					:href="`/downloads/${ caseStudy.pdf }`"
					target="_blank"
					rel="noopener"
				>
					Download case study
				</a>
				<button
					@click="backFromCaseStudy()"
					class="back-button"
				>
					<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 14"><rect width="2" height="9" rx="1" transform="rotate(44.999 2.718 8.259) skewX(-.001)" fill="#8760FF"/><rect width="2" height="9" rx="1" transform="rotate(135.001 1.382 7.654) skewX(.002)" fill="#8760FF"/></svg>
					Go back
				</button>
			</div>
		</div>

		<div class="case-study-content">
			<div class="left-column">
				<svg class="blob-2" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 406 419"><path fill-rule="evenodd" clip-rule="evenodd" d="M144.677 45.91c50.736-11.316 98.851 15.978 140.616 46.955 41.616 30.867 81.79 68.933 85.334 120.635 3.653 53.276-25.521 103.654-67.715 136.349-39.66 30.733-92.828 39.907-140.815 25.242-42.641-13.032-62.74-55.854-85.284-94.34-24.647-42.075-60.238-84.13-46.702-130.97C45.17 97.665 91.75 57.715 144.677 45.911z" fill="#E1D7FF"/></svg>

				<h2 class="treatment">{{ caseStudy.treatment }}</h2>
				<h3 class="name">{{ caseStudy.name }}</h3>

				<div class="drugs-table-wrap">
					<drugs-table 
						:drug-name="caseStudy.drugName"
						:data="caseStudy.drugTable"
						:case-overview="caseStudy.caseOverview"
					/>
				</div>
			</div>

			<div class="right-column">
				<div 
					class="symbol-legend"
					:class="{ 'is-active': symbolLegendOpen }"
				>
					<button
						@click="openLegendClicked"
					>
						Symbol Legend
						<i>
							<svg 
								v-if="!symbolLegendOpen"
								fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14"><path d="M7 1v12M1 7h12" stroke="#8760FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>

							<svg
								v-else
								fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 2"><path d="M1 1h12" stroke="#8760FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
						</i>
					</button>
					<div 
						class="content"
						ref="symbolLegendContent"
						:class="{ 'is-open': symbolLegendOpen }"
						:style="`height: ${symbolLegendHeight}px`"
					>
						<table
							ref="symbolLegendContentTable"
						>
							<tr>
								<td class="icon tick">
									<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21"><path d="M2 13.314l5.657 5.657L18.97 7.657" stroke="#008A00" stroke-width="2" stroke-linecap="round"/></svg>
								</td>
								<td>The key RWD success factor is satisfied</td>
							</tr>
							<tr>
								<td class="icon cross">
									<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14"><path d="M1.657 12.97L12.97 1.658M12.97 12.97L1.658 1.658" stroke="#CA024F" stroke-width="2" stroke-linecap="round"/></svg>
								</td>
								<td>The key RWD success factor is not satisfied</td>
							</tr>
							<tr>
								<td class="icon question">
									<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 22"><path d="M5.191 19.53a1.356 1.356 0 112.712 0v.122a1.356 1.356 0 11-2.712 0v-.121zM1.836 7.267c-.683 0-1.248-.556-1.157-1.233.19-1.4.717-2.564 1.583-3.493C3.37 1.341 4.892.742 6.826.742c1.79 0 3.22.513 4.292 1.54C12.2 3.297 12.74 4.6 12.74 6.19c0 .962-.2 1.743-.6 2.343-.39.599-1.185 1.48-2.384 2.642-.872.844-1.44 1.561-1.703 2.152-.156.354-.263.813-.324 1.377-.071.665-.6 1.21-1.27 1.21s-1.223-.544-1.162-1.211c.069-.754.206-1.394.412-1.921.309-.8.986-1.712 2.03-2.738l1.09-1.076c.327-.308.59-.63.79-.967.364-.59.546-1.203.546-1.838 0-.89-.268-1.662-.804-2.316-.527-.653-1.404-.98-2.63-.98-1.517 0-2.566.563-3.147 1.689-.208.397-.359.906-.453 1.526-.1.656-.631 1.184-1.295 1.184z" fill="#EAAC30"/></svg>
								</td>
								<td>Relevant information is not mentioned in the publicly available reports</td>
							</tr>
							<tr>
								<td class="icon circle">
									<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><circle cx="5" cy="5" r="4" stroke="#005BD4" stroke-width="2"/></svg>
								</td>
								<td>Relevant information is mentioned in the publicly available reports but key RWD success factor was not fully resolved</td>
							</tr>
						</table>
					</div>
				</div>
				

				<results-table 
					:data="caseStudy.resultsTable"
				/>

				<div class="buttons footer-buttons">
					<a
						v-if="caseStudy.pdf"
						:href="`/downloads/${ caseStudy.pdf }`"
						target="_blank"
						rel="noopener"
					>
						Download case study
					</a>
					<button
						@click="backFromCaseStudy()"
						class="back-button"
					>
						<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 14"><rect width="2" height="9" rx="1" transform="rotate(44.999 2.718 8.259) skewX(-.001)" fill="#8760FF"/><rect width="2" height="9" rx="1" transform="rotate(135.001 1.382 7.654) skewX(.002)" fill="#8760FF"/></svg>
						Go back
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import DrugsTable from '@/components/DrugsTable';
import ResultsTable from '@/components/ResultsTable';
import caseStudies from '@/data/case-studies';

export default {
	name: 'case-study',

	components: {
		DrugsTable,
		ResultsTable
	},

	data() {
		return {
			caseStudy: {},
			symbolLegendOpen: false,
			mounted: false,
			symbolLegendHeight: 0
		}
	},

	mounted() {
		this.caseStudy = caseStudies.find(caseStudy => caseStudy.url === this.$route.params.casestudy);

		setTimeout(() => {
			this.mounted = true;
		}, 100)

		if ( !this.caseStudy ) {
			this.$router.push('/');
		}
	},

	methods: {
		backFromCaseStudy() {
			this.mounted = false;

			setTimeout( () => {
				if ( this.$parent.$data.caseStudiesSelection === "" ) {
					this.$router.push('/');
				} else {
					this.$router.go(-1);
				}
			}, 600 );
		},

		openLegendClicked() {
			if ( this.symbolLegendOpen ) {
				this.symbolLegendHeight = 0;	
			} else {
				this.symbolLegendHeight = this.$refs.symbolLegendContentTable.getBoundingClientRect().height + 25;
			}

			this.symbolLegendOpen = !this.symbolLegendOpen;
		}
	}
};

</script>

<style lang="scss" scoped>
	.case-study {
		padding: 128px 48px 72px 48px;
		position: relative;
		opacity: 0;
		transition: opacity .3s ease;

		&.is-active {
			opacity: 1;
		}
	}

	.blob-1 {
		position: absolute;
		width: 800px;
		right: 0;
		top: 0;
		transform: translateY(-15%) translatex(27.5%) rotate(-210deg);
	}

	.blob-2 {
		position: absolute;
		width: 670px;
		bottom: 0;
		transform: translateY(25%) translatex(-40%) rotate(-180deg);
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 70px;
		position: relative;

		h1 {
			max-width: 620px;
			font-size: 32px;
			line-height: 1.2;
			color: #0A1153;
			font-weight: 700;
		}
	}

	.buttons {
		display: flex;

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			text-decoration: none;
			background: #8760FF;
			box-shadow: 4px 8px 32px rgba(135, 96, 255, 0.45);
			border-radius: 8px;
			color: #fff;
			font-weight: 600;
			font-size: 14px;
			width: 215px;
			height: 48px;
			margin-right: 25px;

			transition: background-color .3s ease;

			&:hover {
				background-color: #4A358C;
			}
		}
	}

	.treatment {
		font-size: 24px;
		line-height: 1.3;
		color: #8760FF;
		font-weight: 700;
	}

	.name {
		font-size: 20px;
		line-height: 1.3;
		color: #0A1153;
		font-weight: 700;
	}

	.case-study-content {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		position: relative;
	}

	.left-column {
		width: 42.5%;
		max-width: 512px;
		position: relative;
	}

	.right-column {
		width: 52.5%;
		max-width: 648px;
	}

	.footer-buttons {
		margin-top: 84px;
		justify-content: flex-end;
	}

	.drugs-table-wrap {
		margin-top: 64px;
		position: relative;
	}

	.symbol-legend {
		background-color: #fff;
		border-radius: 16px;
		margin-bottom: 40px;
		box-shadow: 4px 8px 32px rgba(135, 96, 255, 0.25);
		border: 1px solid transparent;
		overflow: hidden;
		transition: border-color .5s ease;

		&.is-active {
			border: 1px solid #8760FF;
		}

		button {
			padding: 16px 24px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			font-weight: 700;
			color: #0A1153;

			i {
				display: flex;
				align-items: center;
				width: 12px;
			}
		}

		.content {
			font-size: 12px;
			overflow: hidden;
			transition: height .5s ease;

			table {
				margin-top: 25px;
			}

			td {
				border-top: 1px solid #D5C7FF;
				padding: 18px 50px;
			}

			.icon {
				width: 70px;
				text-align: center;
				padding: 0;
				vertical-align: middle;

				&.tick {
					background-color: #EAF9DE;

					svg {
						width: 18px;
					}
				}

				&.cross {
					background-color: #FFECFC;

					svg {
						width: 12px;
					}
				}

				&.question {
					background-color: #FFF8E9;

					svg {
						width: 12px;
					}
				}

				&.circle {
					background-color: #E3FEFF;

					svg {
						width: 12px;
					}
				}
			}
		}
	}
</style>
