<template>
	<div class="view">
		<div 
			class="content-inner content-inner-case-studies"
			:class="{ 'is-active': !$parent.$data.selectedCaseStudy && !$parent.$data.isCaseStudy && mounted }"
		>
			<h1 
				v-if="$parent.$data.stepTwoSelection === 3"
				class="question-heading"
			>
				You chose <span>Observational Study</span>. Here is a list of relevant regulatory precedents that successfully achieved a Postmarketing Commitment or Requirement supported by data from an Observational Study.
			</h1>

			<h1 
				v-else
				class="question-heading"
			>
				You chose <span>{{ $parent.$data.caseStudiesSelection }}</span>. Here is a list of relevant regulatory precedents that successfully achieved a {{ $parent.$data.stepTwo.selection }} supported by data from {{ conjunctive }} {{ $parent.$data.caseStudiesSelection }}.
			</h1>

			<h2 
				class="question-sub-heading"
			>
				Click one to learn more
			</h2>
		</div>
	</div>
</template>

<script>

export default {
	name: 'case-studies',

	data () {
		return {
			mounted: false,
		}
	},

	mounted () {
		setTimeout( () => {
			this.mounted = true;
		}, 100 )
	},

	computed: {
		conjunctive() {
			return this.$parent.$data.caseStudiesSelection === "Pragmatic Trial" ? "a" : "an";
		}
	}
};

</script>

<style lang="scss" scoped>
	.content-inner {
		width: 730px;
	}


</style>





 

