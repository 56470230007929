<template>
	<div class="view">
		<div class="content-inner">
			<h1 
				class="question-heading"
				v-html="$parent.$data.stepTwo.questionHeading"
			/>

			<div 
				class="question-intro"
				:class="{ 'is-displayed': questionIntroDisplayed }"
				:style="calculatedHeight"
			>
				<span 
					v-html="questionIntroDisplayed ? $parent.$data.stepTwo.questionIntroFull : $parent.$data.stepTwo.questionIntroShort"
					ref="copy"
				/>
				<button 
					class="read-more-toggle-button"
					@click="readMoreToggleClicked"
				>
					<span v-if="questionIntroDisplayed">Read less</span>
					<span v-else>Read more</span>
				</button>
			</div>

			<h2 
				class="question-sub-heading"
				v-html="$parent.$data.stepTwo.questionSubHeading"
			/>
		</div>
	</div>
</template>

<script>
export default {
	name: 'study-design-type',

	data() {
		return {
			questionIntroDisplayed: false,
			copyHeight: 23
		}
	},

	mounted() {
		this.copyHeight = this.$refs.copy.getBoundingClientRect().height;
	},

	computed: {
		calculatedHeight() {
			return `height: ${this.copyHeight}px;`;
		}
	},

	methods: {
		readMoreToggleClicked() {
			this.questionIntroDisplayed = !this.questionIntroDisplayed;

			const buttonHeight = this.questionIntroDisplayed ? 50 : 0;

			setTimeout(() => {
				this.copyHeight = this.$refs.copy.getBoundingClientRect().height + buttonHeight;
			}, 50);
		}
	}
};

</script>

<style lang="scss" scoped>
	.content-inner {
		width: 730px;
	}
</style>





 

