export default [
	{
		selection: "New Product Approval",
		questionHeading: "You chose <span>New Product Approval</span>. When thinking about study designs, here are some potential options/considerations:",
		questionIntroShort: `<p>Evidence in support of effectiveness or safety...</p>`,
		questionIntroFull: `
			<p>Evidence in support of effectiveness or safety</p>
			<ul>
				<li>
					<span>Rare disease space has provided an early testing ground for the use of RWE <strong>external controls</strong> in decisions about effectiveness. Approvals may include one or more single arms trials and are limited to:</span>
					<ul>
						<li>Rare/orphan settings</li>
						<li>High unmet need</li>
						<li>RCT unfeasible because placebo or existing treatments are unsatisfactory</li>
						<li>Single arm effect are substantial</li>
					</ul>
				</li>
				<li><span><strong>Observational data</strong> from expanded access program may support new product approval</span></li>
			</ul>
		`,
		questionSubHeading: "What RW study design are you considering? Click one of the following categories:"
	},
	{
		selection: "Label Expansion",
		questionHeading: "You chose <span>Label Expansion</span> (new indication or additional clinical data). When thinking about study designs, here are some potential options/considerations:",
		questionIntroShort: `<p>New Indication...</p>`,
		questionIntroFull: `
			<p>New Indication</p>
			<ul>
				<li>
					<span><strong>External controls</strong>, whether as a benchmark or more formal comparator, have been utilized in situations where an RCT is not feasible.</span>
				</li>
				<li>
					<span>Additionally, data from off-label usage in the form of an <strong>Observational Study</strong> have in select cases been used to help demonstrate efficacy in new populations or indications.</span>
				</li>
			</ul>
			<p>Additional Clinical Data</p>
			<ul>
				<li>
					<span>New comparative effectiveness or safety information may be derived from <strong>Pragmatic Trials</strong> when the outcome of interest is captured in clinical practice and there is an active comparator.</span>
				</li>
				<li>
					<span><strong>Observational Studies</strong> may similarly be used to introduce new clinical data for approved drugs within their indication.</span>
				</li>
			</ul>
		`,
		questionSubHeading: "What RW study design are you considering? Click one of the following categories:"
	},
	{
		selection: "Postmarketing Commitment or Requirement",
		questionHeading: "You chose <span>Postmarketing Commitment or Requirement</span>. When thinking about study designs, here are some potential options/considerations:",
		questionIntroShort: `<p>While observational studies have traditionally been used...</p>`,
		questionIntroFull: `
			<ul>
				<li><span>While <strong>observational studies</strong> have traditionally been used to support post-marketing safety requirements, newer applications include confirming long-term efficacy outcomes for accelerated or conditional approvals.</span></li>
				<li><span>Additionally, as companies proactively plan to meet postmarketing requirements, innovative real-world extensions of clinical trials are expected to emerge as a future opportunity.</span></li>
			</ul>
		`,
		questionSubHeading: "What RW study design are you considering? Click one of the following categories:"
	}
]