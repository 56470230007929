<template>
	<div class="view">
		<div class="content-inner">
			<h1 class="question-heading">First, some general considerations:</h1>

			<div 
				class="question-intro"
				:class="{ 'is-displayed': questionIntroDisplayed }"
				:style="calculatedHeight"
			>
				<span 
					v-html="questionIntroDisplayed ? questionIntroFull : questionIntroShort"
				/>
				<button 
					class="read-more-toggle-button"
					@click="readMoreToggleClicked"
				>
					<span v-if="questionIntroDisplayed">Read less</span>
					<span v-else>Read more</span>
				</button>
			</div>

			<h2 class="question-sub-heading">
				Sound similar to your situation? If so, what is your regulatory objective? Click one of the following categories:
			</h2>
		</div>
	</div>
</template>

<script>
export default {
	name: 'regulatory-objective',

	computed: {
		calculatedHeight() {
			const height = this.questionIntroDisplayed ? 210 : 44;
			return `height: ${height}px;`;
		}
	},

	data() {
		return {
			questionIntroDisplayed: false,
			questionIntroShort: `<p>RWE has been considered as supportive data, or in select cases as a central evidence in achieving a regulatory objective...`,
			questionIntroFull: `<p>RWE has been considered as supportive data, or in select cases as a central evidence in achieving a regulatory objective. For example, in situations with:</p>

				<ul>
					<li><span>Rare diseases</span></li>
					<li><span>High magnitude of treatment effect</span></li>
					<li><span>Randomized clinical trial (RCT) not feasible</span></li>
					<li><span>Real world data (RWD) available or can be generated</span></li>
				</ul>`
		}
	},

	methods: {
		readMoreToggleClicked() {
			this.questionIntroDisplayed = !this.questionIntroDisplayed;
		}
	}
};

</script>

<style lang="scss" scoped>
	.content-inner {
		width: 730px;
	}
</style>





 

