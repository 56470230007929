<template>
	<button class="case-study-card">
		<div class="copy">
			<span>{{ caseStudyDrugName }}</span>
			<h3>{{ caseStudyName }}</h3>
		</div>
		<i class="arrow">
			<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 15"><rect x="8.071" y="14.142" width="1" height="10" rx=".5" transform="rotate(-135 8.071 14.142)" /><rect width="1" height="10" rx=".5" transform="rotate(-45 4.535 -8.536)" /><rect y="7.5" width="1" height="14" rx=".5" transform="rotate(-90 0 7.5)" /></svg>
		</i>
	</button>
</template>

<script>
export default {
	name: 'case-study-card',

	props: {
		index: {
			type: [Number, String],
			default: () => ""
		},

		caseStudyDrugName: {
			type: String,
			default: () => ""
		},

		caseStudyName: {
			type: String,
			default: () => ""
		}
	}
};

</script>

<style lang="scss" scoped>
	.case-study-card {
		box-shadow: 0 0 40px rgba(135, 96, 255, 0.2);
		background-color: #fff;
		width: 240px;
		border-radius: 16px;
		text-align: left;
		padding: 32px 24px;
		display: flex;
		align-items: center;
		transition: background-color .8s ease;

		&:hover,
		&:active {
			background-color: #9370FF;

			.arrow {
				background-color: #fff;

				svg {
					fill: #9370FF;
				} 
			}

			span, h3 {
				color: #fff;
			}
		}

		&:active {
			background-color: #4A358C;
		}

		span {
			display: block;
			font-size: 10px;
			line-height: 120%;
			letter-spacing: 2px;
			color: #8760FF;
			font-weight: 700;
			text-transform: uppercase;
			transition: color .8s ease;
		}

		h3 {
			margin-top: 4px;
			font-size: 14px;
			line-height: 1.2;
			color: #0A1153;
			font-weight: 700;
			flex-grow: 1;
			transition: color .8s ease;
		}
	}

	.copy {
		flex-grow: 1;
	}

	.arrow {
		flex-shrink: 0;
		width: 36px;
		height: 36px;
		background-color: #8760FF;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 100%;
		margin-left: 10px;
		transition: background-color .8s ease;

		svg {
			width: 60%;
			fill: #fff;
		}
	}
</style>