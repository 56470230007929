export default [
	{
		url: "somatostatin-receptor-positive",
		pdf: 'Lutetium_Lu_177_dotatate.pdf',
		name: "Somatostatin receptor-positive, neuroendocrine tumors gastroenteric or pancreatic origin (SSTR+ GEP-NET)",
		studyType: "Observational Study",
		regObjective: "New Product Approval",
		treatment: "Lutetium Ly 177 dotatate",
		description: "Observational data from expanded access program to support new product approval in somatostatin receptor+, neuroendocrine tumors",
		drugName: "Lutetium Lu 177 dotatate",
		drugTable: {
			"Regulatory objective": "New product approval",
			"Study type": "Observational study",
			"RWD source": "Expanded access program at a large academic medical center",
			"Indication": "Somatostatin receptor-positive, neuroendocrine tumors of gastroenteric or pancreatic origin (SSTR+ GEP-NET)",
			"Rare Disease?": "Yes",
			"High urgency to treat?": "Yes",
			"FDA decision": "Approved, 2018",
			"EMA decision": "Approved, 2017"
		},
		caseOverview: "The Erasmus Medical Center (EMC) Phase 1/2 study was a monocentric single arm open label study to evaluate the efficacy of Lutetium Lu 177 dotatate co-administered with amino acid solution in patients with somatostatin receptor positive tumors. In 2000, this study allowed treatment of a heterogenous group of patients with somatostatin receptor positive tumors with 177Lu-Oxodotreotide on a compassionate use basis. Data from the Erasmus study was submitted together with data from NETTER-1, a multicenter, stratified, open, randomized, comparator controlled, parallel-group Phase 3 study comparing treatment with 177LuOxodotreotide to Octreotide LAR in patients within operable, progressive, somatostatin receptor positive, midgut carcinoid tumors.",
		resultsTable: [
			{
				icon: "tick",
				key: "Clear endpoint",
				value: "The primary efficacy variables for the integrated analysis of NETTER-1 and the Erasmus study were objective response rate/best overall tumour response and duration of response. The secondary efficacy endpoints were overall survival and progression-free survival. Endpoints were clearly defined and complete response (CR) and partial response (PR) were determined according to RECIST criteria."
			},
			{
				icon: "tick",
				key: "Minimized biases",
				value: "The patients in the Erasmus study included in the pooled full analysis set were those that met the main eligibility criteria of the NETTER-1 study. Data across studies were pooled for analyses. Sensitivity analyses were conducted, including patients without baseline tumour assessment as nonresponders showed that the estimated ORR were lower."
			},
			{
				icon: "tick",
				key: "Complete",
				value: "The efficacy data from the Erasmus study was derived from the sub-group of Dutch patients since they had the most complete and accurate data in comparison to non-Dutch patients. In 2011, the applicant conducted a source data verification, including data integrity for efficacy, of the Phase 1/2 trial at the EMC in a subgroup of patients with midgut carcinoid tumours that were progressive within 12 months of the study entry. The first independent assessment was conducted in 2012."
			},
			{
				icon: "tick",
				key: "Transparent",
				value: "The objectives and the inclusion/exclusion criteria of the Erasmus study were clearly described. Endpoints were validated, and sensitivity analyses were conducted. The applicant discusses potential overestimation of OS and PFS in the Erasmus study (Dutch population)."
			},
			{
				icon: "cross",
				key: "Generalizable",
				value: "The Erasmus study included patients from only one center."
			},
			{
				icon: "circle",
				key: "Timely",
				value: "Patients were  enrolled in the Erasmus study between January 2000 and December 2012. The Erasmus study precedes the NETTER-1 study; results from the Erasmus study led to the development of the Phase 3 study (NETTER-1)."
			},
			{
				icon: "question",
				key: "Scalable",
				value: "The study required discrete, supportive use of RWD that was not intended to be scaled to multiple context. "
			}
		]
	},
	{
		url: "male-metastatic-breast-cancer",
		pdf: 'Palbociclib.pdf',
		name: "Male metastatic breast cancer (mBC)",
		studyType: "Observational Study",
		regObjective: "Label Expansion",
		treatment: "Palbociclib",
		description: "Multiple observational studies using Electronic health record (EHR), claims, and safety data to support label expansion for new indication",
		drugName: "Palbociclib",
		drugTable: {
			"Regulatory objective": "Label expansion – new indication ",
			"Study type": "Observational study",
			"RWD source": "EHR, claims and global safety database",
			"Indication": "Metastatic male breast cancer",
			"Rare Disease?": "Yes – rare in males",
			"High urgency to treat?": "Yes – limited treatment options for males",
			"FDA decision": "Approved, 2019",
			"EMA decision": "N/A"
		},
		caseOverview: "FDA granted a label expansion for palbociclib in men, referencing a combination of real-world and clinical trial data. Real-world data were sourced from three databases: IQVIA insurance database, Flatiron Health Breast Cancer database, and the Pfizer global safety database. ",
		resultsTable: [
			{
				icon: "tick",
				key: "Clear endpoint",
				value: "Evidence was needed to demonstrate safety in males with metastatic breast cancer. “Substantial evidence” for efficacy came from traditional pivotal trials in women."
			},
			{
				icon: "cross",
				key: "Minimized biases",
				value: "FDA concluded that it was not possible to make comparisons between patients treated with palbociclib vs. other treatments in the EHR database and treated the submission as a single-arm trial offering supportive evidence. This retrospective analysis is exploratory in nature and no formal statistical comparisons between groups were performed. Due to small sample size, procedures employed to render cohorts in observational studies more comparable (e.g., matching, propensity scores, etc.) were not used in this study."
			},
			{
				icon: "circle",
				key: "Complete",
				value: "Predeﬁned rules for abstraction were listed, but were not benchmarked to appropriate gold standards. Flatiron data provided supportive evidence for effectiveness. Analysis of safety in the Flatiron study was limited to the 5 most common adverse events known to occur with palbociclib. Given this restriction, the identification of new adverse events/safety signals in male patients is limited. Comparisons of the incidence of these AEs versus the incidence that has been observed in clinical trials is limited by possible differences in adverse events recording practices."
			},
			{
				icon: "tick",
				key: "Transparent",
				value: "The speciﬁc aims and cohort selection criteria were precisely deﬁned. However it was difficult to know whether criteria for HER2-negative disease followed American Society of Clinical Oncology/College of American Pathologists (ASCO/CAP) guidelines. FDA inspected data sites, which helped to ensure transparency and prevent cherry-picking."
			},
			{
				icon: "question",
				key: "Generalizable",
				value: "Unclear"
			},
			{
				icon: "tick",
				key: "Timely",
				value: "Flatiron: Confirmation of metastatic disease (via review of unstructured data) on or after January 1, 2011."
			},
			{
				icon: "question",
				key: "Scalable",
				value: "Unclear"
			}
		]
	},
	{
		url: "paroxysmal-nocturnal-hemoglobinuria",
		pdf: 'Eculizumab.pdf',
		name: "Paroxysmal nocturnal hemoglobinuria (PNH)",
		studyType: "Observational Study",
		regObjective: "Label Expansion",
		treatment: "Eculizumab",
		description: "Observational study using registry data to support label expansion for new indication",
		drugName: "Eculizumab",
		drugTable: {
			"Regulatory objective": "Label expansion – new indication",
			"Study type": "Observational study",
			"RWD source": "Registry",
			"Indication": "Paroxysmal nocturnal hemoglobinuria (PNH)",
			"Rare Disease?": "Yes",
			"High urgency to treat?": "Yes",
			"FDA decision": "Not applicable",
			"EMA decision": "Approved, 2015"
		},
		caseOverview: "The pivotal randomized clinical trial, TRIUMPH, was the basis for new product approval in PNH. An observational study was used to demonstrate that the disease staging criteria limiting eculizumab’s indication was inaccurate. The study used existing PNH registry data to generate evidence on standard of care. EMA CHMP agreed that a registry study could determine disease burden and severity.",
		resultsTable: [
			{
				icon: "tick",
				key: "Clear endpoint",
				value: "Primary endpoint was change in lactate dehydrogenase from baseline to 6 months. However, secondary endpoints and exploratory endpoints around quality of life played a key role in the data package. Outcomes were consistent with efficacy endpoints from the original submission."
			},
			{
				icon: "circle",
				key: "Minimized biases",
				value: "Baseline disease characteristics had differences between registry and those included in the original submission, but data indicated symptomatic benefit. Assessment was not blinded, which could have biased evaluation of endpoints such as fatigue, dyspnea, QoL and other related symptoms."
			},
			{
				icon: "cross",
				key: "Complete",
				value: "Data limitations were not unexpected from a registry, however missing data fields of patient reported outcomes led to a number of missing evaluations."
			},
			{
				icon: "tick",
				key: "Transparent",
				value: "Study objectives and patient population was clearly described. PNH registry data were analyzed in a pre-specified manner by a collective global scientific board and managed by an independent group with expertise in observational studies."
			},
			{
				icon: "tick",
				key: "Generalizable",
				value: "PNH Registry has a global presence with over 1,500 patients."
			},
			{
				icon: "tick",
				key: "Timely",
				value: "Final database download occurred on May 1, 2013."
			},
			{
				icon: "tick",
				key: "Scalable",
				value: "Large prospective data registry in this disease area."
			}
		]
	},
	{
		url: "1l-metastatic-melanoma-os",
		pdf: 'Ipilimumab.pdf',
		name: "1L Metastatic Melanoma",
		studyType: "Observational Study",
		regObjective: "Label Expansion",
		treatment: "Ipilimumab",
		description: "Two observational studies using clinical site charts to support pivotal clinical data for label expansion for new indication, as well as fulfill post-marketing requirements",
		drugName: "Ipilimumab",
		drugTable: {
			"Regulatory objective": "Label expansion – new indication; Post-marketing requirement",
			"Study type": "Observational study",
			"RWD source": "EHR chart extraction ",
			"Indication": "1L Melanoma ",
			"Rare Disease?": "No",
			"High urgency to treat?": "Yes",
			"FDA decision": "N/A",
			"EMA decision": "Approved November 2013"
		},
		caseOverview: "Two observational studies CA184-332 (n=61) & CA184-338 (n=120) were conducted on treatment-naïve patients in advanced melanoma.  Along with clinical data from Phase 2 and 3, these data helped inform a positive decision for extending ipilimumab label to include first-line advanced melanoma patients in the EU.",
		resultsTable: [
			{
				icon: "tick",
				key: "Clear endpoint",
				value: "The primary endpoint was overall survival."
			},
			{
				icon: "circle",
				key: "Minimized biases",
				value: "The studies used definitive endpoint of overall survival, limiting opportunities for bias in analysis or data capture."
			},
			{
				icon: "circle",
				key: "Complete",
				value: "Electronic Health Records (EHR) data was collected from multiple oncology sites in the US. Sources included the iKnowMed medical record system. Despite the large number of records and significant patient population, study sample was likely limited by difficult-to-capture variables such a performance score."
			},
			{
				icon: "question",
				key: "Transparent",
				value: "Unknown"
			},
			{
				icon: "tick",
				key: "Generalizable",
				value: "This was a US-based retrospective observational study using EHR data without chart abstraction."
			},
			{
				icon: "tick",
				key: "Timely",
				value: "Data from the observational studies was timely in regards to the Phase 2 and 3 trials."
			},
			{
				icon: "cross",
				key: "Scalable",
				value: "These studies required discrete use of RWD that was not intended to be scaled to multiple contexts "
			}
		]
	},
	{
		url: "adenosine-deaminase-severe-combined-immunodeficiency",
		pdf: 'Autologous.pdf',
		name: "Adenosine deaminase severe combined immunodeficiency (ADA-SCID)",
		studyType: "Observational Study",
		regObjective: "Postmarketing Commitment or Requirement",
		treatment: "Autologous CD34+ enriched cell fraction that contains CD34+ cells transduced with retroviral vector that encodes for the human ADA cDNA sequence",
		description: "Observational study using registry data to fulfill post-marketing requirement for long-term safety and effectiveness",
		drugName: "Autologous CD34+ enriched cell fraction that contains CD34+ cells transduced with retroviral vector that encodes for the human ADA cDNA sequence",
		drugTable: {
			"Regulatory objective": "Post-marketing requirement",
			"Study type": "Observational study",
			"RWD source": "Registry",
			"Indication": "Adenosine deaminase severe combined immunodeficiency (ADA-SCID)",
			"Rare Disease?": "Yes",
			"High urgency to treat?": "Yes",
			"FDA decision": "N/A",
			"EMA decision": "Approved, 2016"
		},
		caseOverview: "The STRIM-004 pivotal trial demonstrated safety of an innovative gene therapy in 12 pediatric patients. Regulators sought additional data on efficacy from long-term follow-up of the original trial population as well as 6 additional patients identified and pooled into an integrated analysis. Regulators granted conditional approval. A non-interventional PASS to investigate the long term safety and effectiveness of product using data from a registry was required.",
		resultsTable: [
			{
				icon: "tick",
				key: "Clear endpoint",
				value: "The primary endpoint was overall survival, with up to 21 years follow-up."
			},
			{
				icon: "tick",
				key: "Minimized biases",
				value: "The registry uses a patient-centric approach to ensure patient retention and reduce loss to follow-up."
			},
			{
				icon: "question",
				key: "Complete",
				value: "The registry design allows for collection of all necessary data. However, the first readout of registry data is anticipated in 2020 and was not yet available at the time of when this manuscript was published; thus, data completeness could not be assessed."
			},
			{
				icon: "tick",
				key: "Transparent",
				value: "Study objectives, patient population, outcome measures and analyses are clearly stated."
			},
			{
				icon: "circle",
				key: "Generalizable",
				value: "This is an international registry for a rare disease, in many countries there may only be a single patient who has received the therapy leading to potential challenges in generalizability."
			},
			{
				icon: "tick",
				key: "Timely",
				value: "The registry was initiated in 2018."
			},
			{
				icon: "circle",
				key: "Scalable",
				value: "The registry has the potential to contribute to the overall understanding of ADA-SCID if expanded to include outcomes from all available patients with ADA-SCID independent of treatment received. Collaboration with additional partners is currently being sought."
			}
		]
	},
	{
		url: "1l-metastatic-melanoma-pcr",
		pdf: 'Ipilimumab.pdf',
		name: "1L Metastatic Melanoma",
		studyType: "Observational Study",
		regObjective: "Postmarketing Commitment or Requirement",
		treatment: "Ipilimumab",
		description: "Two observational studies using clinical site charts to support pivotal clinical data for label expansion for new indication, as well as fulfill post-marketing requirements",
		drugName: "Ipilimumab",
		drugTable: {
			"Regulatory objective": "Label expansion – new indication; Post-marketing requirement",
			"Study type": "Observational study",
			"RWD source": "EHR chart extraction ",
			"Indication": "1L Melanoma ",
			"Rare Disease?": "No",
			"High urgency to treat?": "Yes",
			"FDA decision": "N/A",
			"EMA decision": "Approved November 2013"
		},
		caseOverview: "Two observational studies CA184-332 (n=61) & CA184-338 (n=120) were conducted on treatment-naïve patients in advanced melanoma.  Along with clinical data from Phase 2 and 3, these data helped inform a positive decision for extending ipilimumab label to include first-line advanced melanoma patients in the EU.",
		resultsTable: [
			{
				icon: "tick",
				key: "Clear endpoint",
				value: "The primary endpoint was overall survival."
			},
			{
				icon: "circle",
				key: "Minimized biases",
				value: "The studies used definitive endpoint of overall survival, limiting opportunities for bias in analysis or data capture."
			},
			{
				icon: "circle",
				key: "Complete",
				value: "Electronic Health Records (EHR) data was collected from multiple oncology sites in the US. Sources included the iKnowMed medical record system. Despite the large number of records and significant patient population, study sample was likely limited by difficult-to-capture variables such a performance score."
			},
			{
				icon: "question",
				key: "Transparent",
				value: "Unknown"
			},
			{
				icon: "tick",
				key: "Generalizable",
				value: "This was a US-based retrospective observational study using EHR data without chart abstraction."
			},
			{
				icon: "tick",
				key: "Timely",
				value: "Data from the observational studies was timely in regards to the Phase 2 and 3 trials."
			},
			{
				icon: "cross",
				key: "Scalable",
				value: "These studies required discrete use of RWD that was not intended to be scaled to multiple contexts "
			}
		]
	},
	{
		url: "metastatic-merkel-cell-carcinoma",
		pdf: 'Avelumab.pdf',
		name: "Metastatic Merkel cell carcinoma (MCC)",
		studyType: "External Control",
		regObjective: "New Product Approval",
		treatment: "Avelumab",
		description: "External benchmark using EHR data to support new product approval in metastatic Merkel cell carcinoma",
		drugName: "Avelumab",
		drugTable: {
			"Regulatory objective": "New product approval",
			"Study type": "External control – Real-world benchmark",
			"RWD source": "EHR",
			"Indication": "Metastatic MCC",
			"Rare Disease?": "Yes",
			"High urgency to treat?": "Yes",
			"FDA decision": "Accelerated approval, 2017",
			"EMA decision": "Conditional approval, 2017"
		},
		caseOverview: "Study 100070-Obs001 was a multi-center retrospective observational study to evaluate outcomes under current clinical practice in metastatic MCC and to characterize the natural history of metastatic MCC with respect to treatment. This observational study served as a historic control to further characterize the risk benefit ratio profile of avelumab in metastatic MCC in the context of the natural history of MCC and treatment outcomes with cytotoxic chemotherapy.",
		resultsTable: [
			{
				icon: "tick",
				key: "Clear endpoint",
				value: "The primary endpoint was objective response rate (based on best overall response) as determined by the treating physician according to “clinical judgment” or by an independent auditor according to Response Evaluation Criteria in Solid Tumors (RECIST) 1.1. Other efficacy endpoints included DOR, durable response, TTD, PFS, and OS. All endpoints were calculated for patients with 2L or later chemotherapy and for the 1L chemotherapy populations. "
			},
			{
				icon: "cross",
				key: "Minimized biases",
				value: "The cohort was similar to the patient population in the single arm trial. However, data were subject to selection bias and other problems inherent in the use of an external historical control; therefore, no formal statistical comparisons were undertaken and the data are considered for informational purposes. The FDA stated that data from Study 100070-Obs001 should be included in the text for the ISE to put the study results in context and that these data could not be used for comparative labeling claims. The descriptive analyses and results of Study 100070-Obs001 were considered exploratory."
			},
			{
				icon: "circle",
				key: "Complete",
				value: "Radiology reports and relevant progress notes were independently reviewed by clinical reviewers to characterize response rates to chemotherapy according to RECIST. Assessment of completeness of data is unclear."
			},
			{
				icon: "tick",
				key: "Transparent",
				value: "The objectives and reasons for patient exclusion were provided. Analytic methods were descriptive only as no statistical comparison was performed. An independent review of endpoint data was conducted."
			},
			{
				icon: "cross",
				key: "Generalizable",
				value: "The historic control should be considered as supportive because sample size was low (n=20 for 1L; n=14 for 2L) and patient population was not diverse; however, results of Study 100070-Obs001 are consistent with the limited published literature on outcomes for patients treated with second line chemotherapy."
			},
			{
				icon: "tick",
				key: "Timely",
				value: "RWD was from 2004-2015,and, thus, included data collected prior to and concurrently to the single arm trial."
			},
			{
				icon: "cross",
				key: "Scalable",
				value: "The study required discrete, supportive use of RWD that was not intended to be scaled to multiple context."
			}
		]
	},
	{
		url: "relapsed-or-refractory-large-b-cell-lymphoma",
		pdf: 'Axicabtagene_ciloleucel.pdf',
		name: "Relapsed or refractory large B-cell lymphoma",
		studyType: "External Control",
		regObjective: "New Product Approval",
		treatment: "Axicabtagene ciloleucel",
		description: "External benchmark using pooled RCT and RWD from clinical site charts to support new product approval",
		drugName: "Axicabtagene ciloleucel",
		drugTable: {
			"Regulatory objective": "New product approval",
			"Study type": "External control – Real-world benchmark",
			"RWD source": "EHR chart extraction",
			"Indication": "Relapsed or refractory large B-cell lymphoma",
			"Rare Disease?": "Yes",
			"High urgency to treat?": "Yes",
			"FDA decision": "Full approval, 2017",
			"EMA decision": "Approved, 2018"
		},
		caseOverview: "SCHOLAR-1 is a retrospective global patient-level pooled study, which integrated data from 2 randomized Phase 3 studies and 2 observational databases of patients with refractory diffuse large B-cell lymphoma (DLBCL), primary mediastinal B-cell lymphoma (PMBCL), and transformed follicular lymphoma (TFL). SCHOLAR-1 was developed as a companion study to a single-arm trial (ZUMA-1) to provide context for interpreting the ZUMA-1 results.",
		resultsTable: [
			{
				icon: "tick",
				key: "Clear endpoint",
				value: "The primary endpoint in SCHOLAR-1 was response rate (per International Working Group Response Criteria for the 2 randomized studies and per investigator assessment for the 2 retrospective databases). Secondary endpoints were complete response rate and overall survival."
			},
			{
				icon: "tick",
				key: "Minimized biases",
				value: "Post-hoc standardized analyses of response and survival were undertaken to address potential imbalances in patient characteristics. Two covariates were specified for use in the standardization: last refractory subgroup and the occurrence of SCT at any time after determination of refractory status."
			},
			{
				icon: "question",
				key: "Complete",
				value: "Rules for data abstraction were pre-defined and the subset of subjects who could be documented to meet all elements of the refractory definition used was programmatically identified. Assessment of completeness of data is unclear."
			},
			{
				icon: "tick",
				key: "Transparent",
				value: "SCHOLAR-1 objectives and patient inclusion criteria were provided and endpoints were clearly defined. Details on standardized analyses were also provided."
			},
			{
				icon: "tick",
				key: "Generalizable",
				value: "This was a multi-center study with data sourced from the databases of 3 academic centers and from 2 of the largest randomized, controlled Phase 3 trials, enrolling adult patients with relapsed or refractory disease."
			},
			{
				icon: "question",
				key: "Timely",
				value: "Unclear. Patients with a date of diagnosis prior to 2000 were included but database lock date was not provided."
			},
			{
				icon: "cross",
				key: "Scalable",
				value: "The study required discrete use of RWD that was not intended to be scaled to multiple context."
			}
		]
	},
	{
		url: "infantile-batten-disease",
		pdf: 'Cerliponase_alfa.pdf',
		name: "Infantile batten disease",
		studyType: "External Control",
		regObjective: "New Product Approval",
		treatment: "Cerliponase alfa",
		description: "External comparator using registry data to support new product approval in infantile batten disease",
		drugName: "Cerliponase alfa",
		drugTable: {
			"Regulatory objective": "New product approval",
			"Study type": "External control – Real-world comparator",
			"RWD source": "Registry",
			"Indication": "Infantile batten disease",
			"Rare Disease?": "Yes",
			"High urgency to treat?": "Yes",
			"FDA decision": "Full approval, 2017",
			"EMA decision": "Approved, 2017"
		},
		caseOverview: "An external control was used to compare cerliponase alfa-treated patients from a single arm trial with untreated patients from a natural history cohort. The intention of the natural history analyses was to examine disease onset, symptoms and rate of progression in the context of genotype and imaging biomarkers.",
		resultsTable: [
			{
				icon: "tick",
				key: "Clear endpoint",
				value: "The primary endpoint was HML/ML score; response was defined as a rate of decline < 2 points per 48 weeks."
			},
			{
				icon: "tick",
				key: "Minimized biases",
				value: "Logistic regression and Cox proportional hazards models were adjusted for covariates and 1:1 matching was performed."
			},
			{
				icon: "question",
				key: "Complete",
				value: "Predeﬁned rules for the data abstraction process were not mentioned and assessment of completeness of data is unclear."
			},
			{
				icon: "tick",
				key: "Transparent",
				value: "Study objectives were clearly described. Patients satisfied inclusion criteria for the clinical study. Details on evaluable patient population and reasons for excluding patients from the analyses were provided. Reliable clinical scales to quantify disease severity were administered. Given the non-randomized study design, analyses comparing treated to untreated patients were adjusted for potential confounders and 1:1 matching was performed."
			},
			{
				icon: "tick",
				key: "Generalizable",
				value: "As per the EMA experts’ experience and data from the parents’ association database, the CLN2 disease is fairly homogeneous."
			},
			{
				icon: "tick",
				key: "Timely",
				value: "Data from the natural history database as of 11 August 2016 was used."
			},
			{
				icon: "cross",
				key: "Scalable",
				value: "The study required discrete, supportive use of RWD that was not intended to be scaled to multiple context"
			}
		]
	},
	{
		url: "haploidentical-hematopoietic-stem-cell-transplantation",
		pdf: 'Genetically_modified_allogenic_T_cell_therapy.pdf',
		name: "Haploidentical Hematopoietic Stem Cell Transplantation (HSCT)",
		studyType: "External Control",
		regObjective: "New Product Approval",
		treatment: "Genetically modified allogenic T cell therapy",
		description: "External comparator using registry data to support new product approval in hematopoietic stem cell transplantation",
		drugName: "Genetically modified allogenic T cell therapy",
		drugTable: {
			"Regulatory objective": "New product approval",
			"Study type": "External control – Real-world comparator ",
			"RWD source": "Registry",
			"Indication": "Hematopoietic Stem Cell Transplantation, Graft vs host disease",
			"Rare Disease?": "Yes",
			"High urgency to treat?": "Yes",
			"FDA decision": "N/A",
			"EMA decision": "Approved, 2016 (no longer authorized)"
		},
		caseOverview: "The European Bone Marrow Transplantation (EBMT) patient registry was used to compile an appropriate historical control for a single-arm, open-label Phase 1/2 study and an ongoing open label randomized controlled Phase 3 trial.",
		resultsTable: [
			{
				icon: "tick",
				key: "Clear endpoint",
				value: "Efficacy outcome measures of the pair-matched analysis were OS, LFS, NRM and relapse incidence (RI). Cumulative incidence rates of chronic graft-versus-host disease (cGVHD) were also analyzed."
			},
			{
				icon: "tick",
				key: "Minimized biases",
				value: "To equate the distribution of baseline characteristics between the MM-TK and control group and to reduce bias in treatment effect estimation, a pair-matched analysis was performed (1:4 ratio). Imbalances in baseline characteristics that were not included in the matching strategy were present, which is not unexpected for this type of pair-matched cross study comparison. In order to minimize bias due to differences in the timing of MM-TK infusion, three sensitivity analyses were performed in which patients who had died or relapsed before 4, 6 and 8 weeks after transplant were excluded. "
			},
			{
				icon: "tick",
				key: "Complete",
				value: "A pre-defined strategy to select the control group from the EMBT registry was agreed upon. The EMA has provided a qualification opinion on the EBMT registry, describing the contexts in which EMA considers the use of registry data suitable, including required data and quality standards."
			},
			{
				icon: "tick",
				key: "Transparent",
				value: "Study objectives were clearly described. Control group from EBMT registry was selected based on same criteria as the control arm of the on-going Phase 3 trial. A clear pair matching strategy was provided. "
			},
			{
				icon: "cross",
				key: "Generalizable",
				value: "Uncertainties remain including the impact of differences in baseline characteristics and the inherent bias related to the limited number of patients. Since the comparison was between non-randomized groups, selection bias could be present."
			},
			{
				icon: "tick",
				key: "Timely",
				value: "Transplants performed from 2000 to 2013 in the EBMT registry were included."
			},
			{
				icon: "tick",
				key: "Scalable",
				value: "EBMT registry is well established and the EMA has provided a qualification opinion on the EBMT registry."
			}
		]
	},
	{
		url: "parenteral-nutrition-associated-cholestasis",
		pdf: 'Fish_oil_triglycerides.pdf',
		name: "Parenteral nutrition-associated cholestasis (PNAC)",
		studyType: "External Control",
		regObjective: "New Product Approval",
		treatment: "Fish oil triglycerides",
		description: "External comparator using clinical site charts to support new product approval in parenteral nutrition-associated cholestasis ",
		drugName: "Fish oil triglycerides",
		drugTable: {
			"Regulatory objective": "New product approval",
			"Study type": "External control – Real-world comparator",
			"RWD source": "EHR chart extraction ",
			"Indication": "Parenteral nutrition-associated cholestasis (PNAC)",
			"Rare Disease?": "No",
			"High urgency to treat?": "Yes",
			"FDA decision": "Approved, 2018",
			"EMA decision": "N/A"
		},
		caseOverview: "Efficacy of fish oil triglycerides was evaluated in two open-label single-center clinical trials in pediatric patients with PNAC who required PN for at least 14 days. Trials prospectively enrolled fish oil triglycerides-treated patients and used historical control patients who received a soybean oil-based lipid emulsion as a comparator.",
		resultsTable: [
			{
				icon: "tick",
				key: "Clear endpoint",
				value: "Nutritional efficacy was assessed by biomarkers of lipid metabolism, growth indices, and/or mean changes in fatty acid parameters. The primary endpoint was change in body weight adjusted for age, benchmarked to WHO growth charts. "
			},
			{
				icon: "circle",
				key: "Minimized biases",
				value: "Treated patients were pair-matched in a 2:1 manner to the historical control patients. The quality of HC matching (i.e., the algorithm and outcome) was adequate given the limitations of the available retrospective data. Despite the measures to mitigate bias (i.e., HC matching algorithm, third-party data collection), the decision to start fish oil triglycerides was under the context of compassionate use from original investigator-initiated protocols, and selection bias in the HC cohort cannot be fully eliminated. Inconsistencies in the evaluation of a therapy’s effect, inconsistencies in the quality of patients, and variation among investigators can interfere with a comparison to historical controls."
			},
			{
				icon: "question",
				key: "Complete",
				value: "Predeﬁned rules for the data abstraction process were not mentioned and assessment of completeness of data is unclear. Biases in endpoint estimates could have resulted from missing data, since more missing data were expected to occur in HC patients than in treated patients."
			},
			{
				icon: "tick",
				key: "Transparent",
				value: "Study objectives were described. Historical controls from 1999 to 2011 were patients who received a soybean oil-based lipid emulsion according to the standard of care at the study site. Treated patients were pair-matched in a 2:1 manner to historical control patients."
			},
			{
				icon: "cross",
				key: "Generalizable",
				value: "Efficacy analysis population was primarily male and white."
			},
			{
				icon: "circle",
				key: "Timely",
				value: "Historical controls from 1999 to 2011 were included. Several health care standards and reporting systems changed, and possibly improved, during the period in which HC patients were enrolled."
			},
			{
				icon: "question",
				key: "Scalable",
				value: "Unclear"
			}
		]
	},
	{
		url: "b-cell-precursor-acute-lymphoblastic-leukemia-with-minimal-residual-disease",
		pdf: 'Blinatumomab.pdf',
		name: "B-cell precursor acute lymphoblastic leukemia (ALL) with minimal residual disease (MRD)",
		studyType: "External Control",
		regObjective: "Label Expansion",
		treatment: "Blinatumomab",
		description: "External comparator using clinical site charts to support label expansion for new indication",
		drugName: "Blinatumomab",
		drugTable: {
			"Regulatory objective": "Label expansion – new indication",
			"Study type": "External control – Real-world comparator",
			"RWD source": "EHR chart extraction ",
			"Indication": "B-cell precursor acute lymphoblastic leukemia (ALL) in first or second complete remission with minimal residual disease (MRD) greater than or equal to 0.1%",
			"Rare Disease?": "Yes",
			"High urgency to treat?": "Yes",
			"FDA decision": "Approved, 2018",
			"EMA decision": "Approved, 2019"
		},
		caseOverview: "An historical comparator study (Study 20120310) was submitted to provide subject level historical data on haematological remission rates and survival in a retrospective cohort of ex-US patients as a comparator for a Phase 2, open-label, single-arm study.",
		resultsTable: [
			{
				icon: "tick",
				key: "Clear endpoint",
				value: "Endpoints of interest were hematological relapse-free survival (RFS) and overall survival (OS)"
			},
			{
				icon: "circle",
				key: "Minimized biases",
				value: "Patients in the trial arm that exhibited a complete response (CR) after first treatment cycle were matched to the control arm using propensity score analysis. However, the CR statuses of patients in the control arm were unknown. The data was also limited by (1) study design (e.g. was not designed to test differences in RFS and OS), (2) small sample size, (3) potential for unknown or unmeasured covariates, (4) differences in length of follow-up. Sensitivity analyses were performed to examine differences in outcomes due to change of treatment protocols over time."
			},
			{
				icon: "circle",
				key: "Complete",
				value: "FDA cited potential limitations with respect to the completeness and quality of historical data, but predeﬁned rules for abstraction were clearly stated."
			},
			{
				icon: "tick",
				key: "Transparent",
				value: "Known demographics and baseline characteristics of historical patient population were provided. Study objectives and patient population were detailed. Weighted analysis and propensity score matching was used to compare hematological relapse-free survival (RFS) and overall survival (OS)."
			},
			{
				icon: "cross",
				key: "Generalizable",
				value: "Rates of hemopoietic stem cell transplant (HSCT) were different between the two arms, therefore results could not be generalized across populations as HSCT may impact RFS and OS. Also sample size was small. Source of the historical control population was unclear. "
			},
			{
				icon: "circle",
				key: "Timely",
				value: "Among the HC study, initial ALL diagnosis was after 01 January 1990 and two-thirds of the database patients were diagnosed after 2000."
			},
			{
				icon: "cross",
				key: "Scalable",
				value: "The study required discrete use of RWD that was not intended to be scaled to multiple contexts"
			}
		]
	},
	{
		url: "b-cell-precursor-acute-lymphoblastic-leukemia-diffuse-large-b-cell-lymphoma ",
		pdf: 'Tisagenlecleucel.pdf',
		name: "B-cell precursor acute lymphoblastic leukemia (ALL)/diffuse large B-cell lymphoma (DLBCL)",
		studyType: "External Control",
		regObjective: "Label Expansion",
		treatment: "Tisagenlecleucel",
		description: "External benchmark using clinical site charts to support label expansion for new indication",
		drugName: "Tisagenlecleucel",
		drugTable: {
			"Regulatory objective": "Label expansion – new indication",
			"Study type": "External control – Real-world benchmark",
			"RWD source": "EHR chart extraction ",
			"Indication": "B-cell precursor acute lymphoblasticleukemia (ALL) / diffuse large B-cell lymphoma (DLBCL)",
			"Rare Disease?": "Yes",
			"High urgency to treat?": "Yes",
			"FDA decision": "Approved, 2018",
			"EMA decision": "Approved, 2018"
		},
		caseOverview: "Initial efficacy was demonstrated in the Phase 2, open-label, multicenter, single-arm JULIET trial. Studies from three external data sets (SCHOLAR-1, pooled CORAL extension study and the PIX301 study) were used to provide confirmation of the pre-specified response rate of 20% and a historical context for interpreting the results from the JULIET trial.",
		resultsTable: [
			{
				icon: "tick",
				key: "Clear endpoint",
				value: "SCHOLAR-1: RR, CR and OS from the time that salvage therapy for refractory disease was initiated. Response was assessed using the 1999 International Working Group (IWG) response criteria per local review/ investigator assessment. CORAL: ORR/CR and OS; OS was defined as a) the time from relapse post-ASCT (in patients who had ASCT as the most recent therapy) or b) time from failure of CORAL induction therapy, to death from any cause."
			},
			{
				icon: "tick",
				key: "Minimized biases",
				value: "SCHOLAR-1: The population in SCHOLAR-1 (relapsed) differed from the patient population in the pivotal trial (relapsed and refractory); thus, only patients who met the refractory criteria in the pivotal trial were included in the cross study comparison. Nonetheless, SCHOLAR-1 included a much broader patient population. The MAIC approach was used to further adjust for cross-study difference in patient characteristics. CORAL: This extension study only included patients who had failed two lines of prior therapy while patients from the pivotal trial had any number of prior lines of therapy; MAIC was used to compare outcomes between studies."
			},
			{
				icon: "tick",
				key: "Complete",
				value: "The authors at each institution abstracted the data according to guidelines given in the research project proposal. Upon receipt of data from individual institutions, study authors programmatically applied the speciﬁc refractory search criteria to derive the SCHOLAR-1 analysis set."
			},
			{
				icon: "tick",
				key: "Transparent",
				value: "Study design selection criteria were precisely defined and endpoints were validated. Refractory DLBCL (including subtypes PMBCL and TFL) was deﬁned as progressive disease (received ≥4 cycles of ﬁrst-line therapy) or stable disease (received 2 cycles of later-line therapy) as best response to chemotherapy or relapse ≤12 months after ASCT. TFL and PMBCL were included because they are histologically similar and are clinically treated as large-cell lymphoma."
			},
			{
				icon: "circle",
				key: "Generalizable",
				value: "Patients were enrolled from multiple centers across 4 countries. However, it was not clear if any patients in SCHOLAR-1 received Pixuvri; thus it could be questioned if the SCHOLAR-1 results are fully representative of what could be expected from currently approved therapies in the r/r DLBCL indication."
			},
			{
				icon: "tick",
				key: "Timely",
				value: "SCHOLAR-1 and CORAL data are recent (CORAL initiated enrollment in 2013)"
			},
			{
				icon: "tick",
				key: "Scalable",
				value: "Variables and endpoints are appropriate for using in broader disease context."
			}
		]
	},
	{
		url: "schizophrenia",
		pdf: 'Paliperidone_palmitate.pdf',
		name: "Schizophrenia",
		studyType: "Pragmatic Trial",
		regObjective: "Label Expansion",
		treatment: "Paliperidone palmitate",
		description: "Pragmatic RCT to support label expansion for the addition of new clinical data into the prescribing information",
		drugName: "Paliperidone palmitate",
		drugTable: {
			"Regulatory objective": "Label expansion – additional clinical data ",
			"Study type": "Pragmatic clinical trial",
			"RWD source": "Pragmatic RCT; follow-up and data collection as per standard of care",
			"Indication": "Schizophrenia",
			"Rare Disease?": "No",
			"High urgency to treat?": "No",
			"FDA decision": "Approved, 2018",
			"EMA decision": "N/A"
		},
		caseOverview: "Patients with schizophrenia typically have complicated care management due to comorbid substance abuse, homelessness, and contact with criminal justice system. Ability to reflect real-world practice and broad flexibility for treatment decision-making was needed. The comparative efficacy trial was a 15-month, multi-center, prospective, randomized, open-label, active control study of 444 adults with schizophrenia who have been incarcerated.",
		resultsTable: [
			{
				icon: "tick",
				key: "Clear endpoint",
				value: "The study contained exploratory and pragmatic endpoints that were fit for regulatory purpose. The primary endpoint was time to treatment failure which is highly relevant to experiences of patients with schizophrenia. This includes arrest/incarceration, psychiatric hospitalization, suicide, and discontinuation due to inadequate efficacy or safety concerns."
			},
			{
				icon: "tick",
				key: "Minimized biases",
				value: "Equipoised-stratified, randomization scheme was used to reduce treatment selection bias. Baseline demographics and clinical characteristics did not differ significantly between arms."
			},
			{
				icon: "circle",
				key: "Complete",
				value: "All primary and secondary outcomes were measured during patient visits throughout the study, however, a disproportionate amount of patients lost to follow-up necessitated a complete response letter from the FDA and additional data collection plan."
			},
			{
				icon: "tick",
				key: "Transparent",
				value: "Study objectives, patient population and analytics were clearly described. The intent-to-treat analysis was published prior to the completion of the study."
			},
			{
				icon: "tick",
				key: "Generalizable",
				value: "The pragmatic design ensured that effects of treatment are generalizable to routine practice. Patients were enrolled from 56 sites across the US and Puerto Rico."
			},
			{
				icon: "tick",
				key: "Timely",
				value: "The prospective design ensured timely data collection for the regulatory submission."
			},
			{
				icon: "cross",
				key: "Scalable",
				value: "The study required discrete use of RWD that was not intended to be scaled to multiple context."
			}
		]
	}
]