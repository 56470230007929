<template>
	<table>
		<thead>
			<tr>
				<th></th>	
				<th>Success Factor</th>
				<th>
					Rationale 
					<span v-if="showEma">from EMA</span>
					<span  v-if="showFda">from FDA</span>
				</th>
			</tr>
		</thead>
		<tbody>
			<tr 
				v-for="(row, index) in data"
				:key="`${row.key}-${index}`"
			>
				<td 
					class="icon"
					:class="row.icon"
				>
					<svg
						v-if="row.icon === 'tick'" 
						fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21"><path d="M2 13.314l5.657 5.657L18.97 7.657" stroke="#008A00" stroke-width="2" stroke-linecap="round"/></svg>

					<svg
						v-if="row.icon === 'cross'"
						fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14"><path d="M1.657 12.97L12.97 1.658M12.97 12.97L1.658 1.658" stroke="#CA024F" stroke-width="2" stroke-linecap="round"/></svg>

					<svg
						v-if="row.icon === 'question'"  
						fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 22"><path d="M5.191 19.53a1.356 1.356 0 112.712 0v.122a1.356 1.356 0 11-2.712 0v-.121zM1.836 7.267c-.683 0-1.248-.556-1.157-1.233.19-1.4.717-2.564 1.583-3.493C3.37 1.341 4.892.742 6.826.742c1.79 0 3.22.513 4.292 1.54C12.2 3.297 12.74 4.6 12.74 6.19c0 .962-.2 1.743-.6 2.343-.39.599-1.185 1.48-2.384 2.642-.872.844-1.44 1.561-1.703 2.152-.156.354-.263.813-.324 1.377-.071.665-.6 1.21-1.27 1.21s-1.223-.544-1.162-1.211c.069-.754.206-1.394.412-1.921.309-.8.986-1.712 2.03-2.738l1.09-1.076c.327-.308.59-.63.79-.967.364-.59.546-1.203.546-1.838 0-.89-.268-1.662-.804-2.316-.527-.653-1.404-.98-2.63-.98-1.517 0-2.566.563-3.147 1.689-.208.397-.359.906-.453 1.526-.1.656-.631 1.184-1.295 1.184z" fill="#EAAC30"/></svg>

					<svg
						v-if="row.icon === 'circle'" 
						fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><circle cx="5" cy="5" r="4" stroke="#005BD4" stroke-width="2"/></svg>
				</td>
				<td>{{ row.key }}</td>
				<td class="rationale">{{ row.value }}</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
export default {
	name: 'results-table',

	props: {
		data: {
			type: Array,
			default: () => []
		}
	},

	computed: {
		showEma() {
			return 	this.$route.params.casestudy === "adenosine-deaminase-severe-combined-immunodeficiency" || 
					this.$route.params.casestudy === "paroxysmal-nocturnal-hemoglobinuria";
		},

		showFda() {
			return 	this.$route.params.casestudy === "male-metastatic-breast-cancer" || 
					this.$route.params.casestudy === "parenteral-nutrition-associated-cholestasis";
		}
	}
};

</script>

<style lang="scss" scoped>
	table {
		width: 100%;
		border-radius: 20px;
		overflow: hidden;
		font-size: 14px;
		box-shadow: 4px 20px 32px rgba(135, 96, 255, 0.3);
		background-color: #fff;

		th {
			text-align: left;
			background-color: #EDE7FF;
			color: #0A1153;
			font-weight: 700;
		}

		th, td {
			padding: 20px 24px;
			border-bottom: 1px solid #D5C7FF;
			vertical-align: middle;

			&:nth-child(2) {
				color: #0A1153;
				font-weight: 700;
			}
		}

		.rationale {
			width: 400px;
		}

		.icon {
			width: 40px;
			padding: 0;
			text-align: center;

			&.tick {
				background-color: #EAF9DE;

				svg {
					width: 18px;
				}
			}

			&.cross {
				background-color: #FFECFC;

				svg {
					width: 12px;
				}
			}

			&.question {
				background-color: #FFF8E9;

				svg {
					width: 12px;
				}
			}

			&.circle {
				background-color: #E3FEFF;

				svg {
					width: 12px;
				}
			}
		}

		.case-overview {
			p {
				color: #393939;
				margin-bottom: 24px;

				&:first-child {
					color: #0A1153;
					font-weight: 700;
				}		
			}
		}
	}
</style>